<template>
  <div>
    <div class="box" v-if="data">
      <div class="header">{{ data.title }}</div>
      <slot><div class="content" v-html="data.content"></div></slot>
      <div class="button-box">
        <button
          class="button"
          v-for="(value, key, index) in data.buttons"
          :key="index"
          @click="click(key)"
        >
          {{ value }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: { type: Object, default: null },
  },
  methods: {
    click: function (key) {
      this.data.onclose(key);
    },
  },
};
</script>

<style scoped>
.box {
  background-color: white;
  border-radius: var(--radius-1);
  overflow: hidden;
}
.header {
  background-color: var(--bc1);
  color: var(--fc1);
  padding: var(--gap-1);
  font-size: 1.5em;
  min-height: 27px;
}
.content {
  min-width: 320px;
  min-height: 100px;
  padding: var(--gap-2);
}
.button-box {
  padding: var(--gap-2);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: var(--gap-2);
}
.button {
  background-color: var(--bc1);
  color: var(--fc1);
  padding: var(--gap-1);
  min-width: 100px;
  font-size: 1.5em;
  border: none;
  border-radius: var(--radius-1);
  cursor: pointer;
}
</style>