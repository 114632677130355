<template>
  <div>
    <div class="backgroud">
      <div class="question-zone">
        <tab-bar
          class="question-tab-bar"
          :tabs="questionTab"
          :activeTab="activeQuestionTab"
          @tab-click="questionTabClick"
        ></tab-bar>
        <div class="question-content">
          <score-list-tab
            v-if="patient && activeQuestionTab == 5"
            :drugList="exam_drug_list"
            :scoreList="scoreList"
            v-model:selectUser="selectUser"
          ></score-list-tab>
          <question-tab-drug
            v-if="patient && activeQuestionTab == 0"
            :drugList="exam_drug_list"
            :answers="answers"
          ></question-tab-drug>
          <question-tab-lab
            v-if="patient && activeQuestionTab == 1"
            :labList="patient.measures"
            :measureType="measureType"
          ></question-tab-lab>
          <question-tab-patient
            v-if="patient && activeQuestionTab == 2"
            :patientContent="patient.content"
          ></question-tab-patient>
          <question-tab-patient
            v-if="patient && activeQuestionTab == 3"
            :patientContent="patient.history"
          ></question-tab-patient>
          <question-tab-basic
            v-if="patient && activeQuestionTab == 4"
            :patient="patient"
          ></question-tab-basic>
        </div>
      </div>
      <div class="answer-zone">
        <tab-bar
          class="answer-tab-bar"
          tabAlign="left"
          :tabs="answerTab"
          :activeTab="activeAnswerTab"
          @tab-click="answerTabClick"
        ></tab-bar>
        <div class="answer-content">
          <answer-score-tab
            :examDrug="examDrug(activeAnswerTab)"
            :answer="answer"
            v-model:score="score"
            @modify:score="modifyScore"
          ></answer-score-tab>
        </div>
      </div>
      <div class="action-zone">
        <div class="action-button" @click="saveScore">儲存評分</div>
        <!-- <div class="action-button">前一題</div> -->
        <div class="action-button" @click="toPageExamScore">回題目選單</div>
        <!-- <div class="action-button">下一題</div> -->
      </div>
    </div>
  </div>
</template>

<script>
import { useStore } from "@/stores/useStore";
import scdapi from "@/scdapi.js";
import TabBar from "@/components/TabBar.vue";
import QuestionTabPatient from "@/components/QuestionTabPatient.vue";
import QuestionTabDrug from "@/components/QuestionTabDrug.vue";
import QuestionTabLab from "@/components/QuestionTabLab.vue";
import QuestionTabBasic from "@/components/QuestionTabBasic.vue";
import AnswerScoreTab from "@/components/AnswerScoreTab.vue";
import ScoreListTab from "@/components/ScoreListTab.vue";

export default {
  setup() {
    const store = useStore();
    return {
      store,
    };
  },
  components: {
    "tab-bar": TabBar,
    "question-tab-patient": QuestionTabPatient,
    "question-tab-drug": QuestionTabDrug,
    "question-tab-lab": QuestionTabLab,
    "question-tab-basic": QuestionTabBasic,
    "score-list-tab": ScoreListTab,
    "answer-score-tab": AnswerScoreTab,
  },
  data: function () {
    return {
      questionTab: [
        { label: "評分", value: 5 },
        { label: "處方", value: 0 },
        { label: "LAB", value: 1 },
        { label: "本次診治情形", value: 2 },
        { label: "病史", value: 3 },
        { label: "基本資料", value: 4 },
      ],
      activeQuestionTab: 5,
      activeAnswerTab: null,
      patient: null,
      exam_drug_list: [],
      saveScoreTimer: null, // 定時儲存分數計時器 //
      scoreList: [], // 評分列表 //
      selectUser: null, // 選擇的使用者 //
    };
  },
  methods: {
    questionTabClick(value) {
      this.activeQuestionTab = value;
    },
    answerTabClick(value) {
      this.activeAnswerTab = value;
    },
    examDrug(examDrugId) {
      for (var i = 0; i < this.exam_drug_list.length; i++) {
        if (this.exam_drug_list[i].exam_drug_id == examDrugId) {
          return this.exam_drug_list[i];
        }
      }
      return null;
    },
    toPageExamScore() {
      this.saveScore();
      this.$router.push("/exam_score/" + this.exam_id);
    },
    modifyScore(user_id) {
      this.scoreList.find((x) => x.user_id == user_id).modifyed = true;
    },
    async saveScore() {
      // 儲存答案 //
      this.scoreList.forEach(e => {
        if (e.modifyed) {
          scdapi.saveScore(this.question_id, e.user_id, e.score_status, e.score_comment);
          e.modifyed = false;
        }
      });
    },
    startTimer() {
      this.saveScoreTimer = setInterval(this.saveScore, 10000);
    },
    endTimer() {
      if (this.saveScoreTimer) {
        clearInterval(this.saveScoreTimer);
      }
    },
  },
  computed: {
    exam_id() {
      return this.$route.params.exam_id;
    },
    question_id() {
      return this.$route.params.question_id;
    },
    answers() {
      if (this.selectUser === null) {
        return [];
      }
      return this.scoreList.find((x) => x.user_id == this.selectUser).answers;
    },
    answerTab() {
      var result = [];
      this.answers.forEach((e) => {
        const drug = this.examDrug(e.exam_drug_id);
        if (drug) {
          result.push({
            label: e.exam_drug_id + " " + drug.scientific_name,
            value: e.exam_drug_id,
          });
        }
      });
      return result;
    },
    answer() {
      for (var i = 0; i < this.answers.length; i++) {
        if (this.answers[i].exam_drug_id == this.activeAnswerTab) {
          return this.answers[i];
        }
      }
      return null;
    },
    score() {
      if (this.selectUser === null) {
        return null;
      }
      return this.scoreList.find((x) => x.user_id == this.selectUser);
    },
  },
  watch: {
    selectUser: {
      handler: function () {
        if (this.selectUser !== null) {
          this.activeAnswerTab =
            this.answers.length > 0 ? this.answers[0].exam_drug_id : null;
        }
      },
    },
  },
  mounted: async function () {
    try {
      // 讀取共同資料 //
      this.measureType = await scdapi.getMeasureType();
      // 讀取題目 //
      var result = await scdapi.getQuestion(this.question_id);
      this.patient = result.patient;
      this.exam_drug_list = result.exam_drug_list;
      // 讀取作答人列表 //
      var scoreList = await scdapi.getScoreList(this.question_id);
      // 刪除不在藥品列表的答案(因為題目異動造成問題) //
      var exam_drug_ids = result.exam_drug_list.map(e => e.exam_drug_id);
      scoreList.forEach(score => score.answers = score.answers.filter(e => exam_drug_ids.includes(e.exam_drug_id)));
      this.scoreList = scoreList;

      if (this.scoreList.length > 0) {
        this.selectUser = this.scoreList[0].user_id;
      }
      // 自動儲存 //
      this.startTimer();
    } catch (e) {
      await this.store.alert(e.message);
      this.toPageExamScore();
    }
  },
  beforeUnmount: function () {
    this.endTimer();
  },
};
</script>

<style scoped>
.backgroud {
  background-color: var(--bc2);
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: row;
}
.question-zone {
  flex: auto;
  box-sizing: border-box;
  width: 50%;
  height: 100%;
  padding: var(--gap-2);
  padding-right: var(--gap-1);
  display: flex;
  flex-direction: column;
}
.answer-zone {
  flex: auto;
  box-sizing: border-box;
  width: 50%;
  height: 100%;
  padding: var(--gap-2);
  padding-left: var(--gap-1);
  display: flex;
  flex-direction: column;
}
.question-tab-bar {
  flex: none;
  height: 36px;
}
.answer-tab-bar {
  flex: none;
  height: 36px;
}
.question-content {
  flex: auto;
  background-color: white;
  display: flex;
  flex-direction: column;
  height: 1px;
  background-color: white;
}
.question-content > * {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
}
.answer-content {
  flex: auto;
  background-color: white;
  height: 1px;
}
.answer-content > * {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
}
.action-zone {
  position: fixed;
  top: var(--gap-1);
  right: var(--gap-2);
  display: flex;
  flex-direction: row;
  gap: var(--gap-1);
}
.action-button {
  color: var(--fc5);
  background-color: var(--bc5);
  padding: var(--gap-1);
  border-radius: var(--radius-1);
  cursor: pointer;
}
</style>
