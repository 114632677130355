<template>
  <div class="board-outer">
    <div class="board-box">
      <div class="hint">
        <div class="hint-time">意見回饋時間：{{ data.create_time }}</div>
        <div class="hint-name">{{ data.create_user_name }}</div>
      </div>
      <div class="content" v-html="data.content"></div>
    </div>
  </div>
</template>

<script>
export default {
  setup() {},
  props: {
    data: null,
  },
};
</script>

<style scoped>
.board-outer {
  cursor: pointer;
}
.board-box {
  display: flex;
  flex-direction: column;
  min-height: 100px;
  max-height: 200px;
  overflow: hidden;
  pointer-events: none;
}
.hint {
  color: var(--fc6);
  background-color: var(--bc6);
  padding: var(--gap-1);
  font-size: 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.hint-time {
  flex: auto;
}
.hint-name {
  flex: none;
}
.title {
  color: var(--fc7);
  background-color: #EEEEEE;
  padding: var(--gap-1);
  font-size: 18px;
}
.content {
  color: var(--fc4);
  background-color: #EEEEEE;
  padding: var(--gap-1);
  font-size: 14px;
  white-space: pre-wrap;
}
</style>