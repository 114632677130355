<template>
  <div>
    <main-layout>
      <div class="content-zone">
        <div class="content-box">
          <div class="content-data">
            <response-overview
              v-for="response in responseList"
              :key="response.id"
              :data="response"
            ></response-overview>
          </div>
        </div>
        <div class="content-label">查看反饋意見</div>
      </div>
    </main-layout>
  </div>
</template>

<script>
import { useStore } from "@/stores/useStore";
import scdapi from "@/scdapi.js";
import MainLayout from "@/layouts/MainLayout.vue";
import ResponseOverview from "@/components/ResponseOverview.vue";

export default {
  setup() {
    const store = useStore();
    return {
      store,
    };
  },
  name: "PageListResponseForm",
  components: {
    MainLayout,
    ResponseOverview,
  },
  data: function () {
    return {
      responseList: [],
    };
  },
  mounted: async function () {
    try {
      this.responseList = await scdapi.listResponse();
    } catch (e) {
      this.store.alert(e.message);
    }
  },
};
</script>

<style scoped>
.content-zone {
  flex: auto;
  padding: var(--gap-1);
  display: flex;
  flex-direction: row;
}
.content-box {
  flex: auto;
  margin: var(--gap-1);
  border: 1px solid var(--bc1);
  border-radius: var(--radius-1);
  padding: var(--gap-4);
  display: flex;
  flex-direction: column;
}
.content-data {
  overflow-y: auto;
}
.content-label {
  position: absolute;
  padding: var(--gap-1);
  background-color: var(--bc1);
  color: var(--fc1);
  border-radius: var(--radius-1);
}
</style>