<template>
  <div class="tab-box-outer">
    <div class="table-box" v-if="drug" ref="tableBox">
      <table class="table">
        <tr>
          <th>序號</th>
          <th>開方日</th>
          <th>處方號</th>
          <th>藥品名稱</th>
          <th class="short">劑量</th>
          <th class="short">單位</th>
          <th class="short">頻率</th>
          <th class="short">途徑</th>
          <th>停用日</th>
          <th>OPD</th>
          <th>註記</th>
          <th>功能</th>
        </tr>
        <tr v-for="(data, row) in drug" :key="row">
          <td>{{ row + 1 }}</td>
          <td>
            <input type="date" v-model="data.startdatetime" />
          </td>
          <td>{{ row + 1 }}</td>
          <td>
            <input type="text" v-model="data.scientific_name" />
          </td>
          <td class="short">
            <input type="text" v-model="data.orderdose" />
          </td>
          <td class="short">
            <input type="text" v-model="data.doseunit" />
          </td>
          <td class="short">
            <input type="text" v-model="data.repeatpatterncode" />
          </td>
          <td class="short">
            <input type="text" v-model="data.routecode" />
          </td>
          <td>
            <input type="date" v-model="data.enddatetime" />
          </td>
          <td>
            <input type="checkbox" v-model="data.opd_medication" />
          </td>
          <td>
            <input type="text" v-model="data.memo" />
          </td>
          <td>
            <div class="table-button" @click="removeDrug(row)">刪除</div>
          </td>
        </tr>
      </table>
    </div>
    <table class="table">
      <tr>
        <th>序號</th>
        <th>開方日</th>
        <th>處方號</th>
        <th>藥品名稱</th>
        <th class="short">劑量</th>
        <th class="short">單位</th>
        <th class="short">頻率</th>
        <th class="short">途徑</th>
        <th>停用日</th>
        <th>OPD</th>
        <th>註記</th>
        <th>功能</th>
      </tr>
      <tr>
        <td></td>
        <td>
          <input type="date" v-model="addData.startdatetime" />
        </td>
        <td></td>
        <td>
          <input type="text" v-model="addData.scientific_name" />
        </td>
        <td class="short">
          <input type="text" v-model="addData.orderdose" />
        </td>
        <td class="short">
          <input type="text" v-model="addData.doseunit" />
        </td>
        <td class="short">
          <input type="text" v-model="addData.repeatpatterncode" />
        </td>
        <td class="short">
          <input type="text" v-model="addData.routecode" />
        </td>
        <td>
          <input type="date" v-model="addData.enddatetime" />
        </td>
        <td>
          <input type="checkbox" v-model="addData.opd_medication" />
        </td>
        <td>
          <input type="text" v-model="addData.memo" />
        </td>
        <td>
          <div class="table-button" @click="addDrug()">新增</div>
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
import { computed, nextTick } from "vue";

export default {
  setup(props, { emit }) {
    const drug = computed({
      get: () => props.drugList,
      set: (val) => emit("update:labList", val),
    });
    return { drug };
  },
  props: {
    drugList: null,
  },
  data: function () {
    return {
      addData: {
        startdatetime: null,
        scientific_name: null,
        orderdose: null,
        doseunit: null,
        repeatpatterncode: null,
        routecode: null,
        enddatetime: null,
        opd_medication: false,
      },
    };
  },
  methods: {
    td(item, column) {
      if (column.method) {
        return column.method(item[column.key]);
      }
      return item[column.key];
    },
    removeDrug(row) {
      this.drug.splice(row, 1);
    },
    async addDrug() {
      this.drug.push({
        startdatetime: this.addData.startdatetime,
        scientific_name: this.addData.scientific_name,
        orderdose: this.addData.orderdose,
        doseunit: this.addData.doseunit,
        repeatpatterncode: this.addData.repeatpatterncode,
        routecode: this.addData.routecode,
        enddatetime: this.addData.enddatetime,
        opd_medication: this.addData.opd_medication,
      });
      const tableBox = this.$refs.tableBox;
      await nextTick();
      tableBox.scrollTop = tableBox.scrollHeight;
    },
  },
};
</script>

<style scoped>
.tab-box-outer {
  padding: var(--gap-4);
  display: flex;
  flex-direction: column;
  gap: var(--gap-2);
}
.table-box {
  flex: auto;
  width: 100%;
  overflow-y: auto;
}
/** css about table */
.table * {
  --table-text: #444444;
  --table-bg0: white;
  --table-bg1: #4f618a;
  --table-bg2: #adc1f1;
  --table-bg3: #cedaf6;
}
.table {
  width: 100%;
  border-spacing: 1px;
  font-size: 14px;
  background-color: var(--table-bg0);
}
.table th {
  text-align: center;
  background-color: var(--table-bg1);
  color: var(--table-bg0);
  padding: 5px;
  position: sticky;
  top: 1px;
}
.table tr:nth-child(2n) td {
  background-color: var(--table-bg2);
}
.table tr:nth-child(2n + 1) td {
  background-color: var(--table-bg3);
}
.table td {
  text-align: center;
  color: var(--table-text);
  padding: 5px;
}
.table .short {
  width: 5%;
}
.table input {
  width: 100%;
  box-sizing: border-box;
  height: 28px;
  background-color: transparent;
  border: 1px solid var(--table-text);
}
.table select {
  width: 100%;
  box-sizing: border-box;
  height: 28px;
  background-color: transparent;
}
.table .table-button {
  background-color: var(--table-bg1);
  color: var(--table-bg0);
  padding: 5px 16px;
  border-radius: var(--radius-1);
  cursor: pointer;
}
</style>
