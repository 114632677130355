<template>
  <div>
    <div class="question-box" v-if="question">
      <div class="title">{{ question.subject }}</div>
      <div class="content">{{ question.content }}</div>
      <div>
        <div class="score-button" v-if="score" @click="onQuestionScore()">評分</div>
        <div class="question-button" v-else @click="onQuestion()">答題</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  setup() {},
  props: {
    question: null,
    score: { type: Boolean, default: false },
  },
  emits: ["on-question", "on-question-score"],
  methods: {
    onQuestion() {
      this.$emit("on-question", this.question.id);
    },
    onQuestionScore() {
      this.$emit("on-question-score", this.question.id);
    },
  },
};
</script>

<style scoped>
.question-box {
  display: flex;
  flex-direction: column;
  gap: var(--gap-1);
}
.title {
  color: var(--fc7);
  background-color: var(--bc7);
  font-size: 18px;
}
.content {
  color: var(--fc4);
  background-color: var(--bc7);
  font-size: 14px;
}
.question-button {
  display: inline-block;
  color: var(--fc1);
  background-color: var(--bc1);
  padding: var(--gap-1);
  border-radius: var(--radius-1);
  cursor: pointer;
}
.score-button {
  display: inline-block;
  color: var(--fc5);
  background-color: var(--bc5);
  padding: var(--gap-1);
  border-radius: var(--radius-1);
  cursor: pointer;
}
</style>
