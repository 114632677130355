<template>
  <div>
    <main-layout>
      <div class="content-zone">
        <div class="content-box">
          <div class="button-row">
            <div class="add-button" v-if="store.hasPrivilege('c@account_add')" @click="showAddAccountDialog()">
              新增帳號
            </div>
          </div>
          <div class="content-data">
            <table class="table">
              <tr>
                <th v-for="(column, index) in columns" :key="index">
                  {{ column.label }}
                </th>
                <th class="function-column">功能</th>
              </tr>
              <tr v-for="(data, row) in accountList" :key="row">
                <template v-for="(column, index) in columns" :key="index">
                  <td v-if="column.html" v-html="td(data, column)"></td>
                  <td v-else>
                    {{ td(data, column) }}
                  </td>
                </template>
                <td class="function-column">
                  <div class="table-function">
                    <div
                      class="table-button"
                      v-if="store.hasPrivilege('c@account_modify')"
                      @click="modifyStatus(data.id, data.status == 1 ? 0 : 1)"
                    >
                      {{ data.status == 1 ? "停用" : "啟用" }}
                    </div>
                    <div
                      class="table-button"
                      v-if="store.hasPrivilege('c@account_modify')"
                      @click="showModifyAccountDialog(data.id)"
                    >
                      修改帳號
                    </div>
                    <div
                      class="table-button"
                      v-if="store.hasPrivilege('c@account_modify')"
                      @click="showChangePasswordDialog(data.id)"
                    >
                      變更密碼
                    </div>
                  </div>
                </td>
              </tr>
            </table>
          </div>
        </div>
        <div class="content-label">帳號管理</div>
      </div>
    </main-layout>
  </div>
</template>

<script>
import { shallowRef, toRaw } from "vue";
import scdapi from "@/scdapi.js";
import { useStore } from "@/stores/useStore";
import MainLayout from "@/layouts/MainLayout.vue";
import AccountModifyDialog from "@/components/AccountModifyDialog.vue";
import AccountChangePassword from "@/components/AccountChangePassword.vue";
import AccountAddDialog from "@/components/AccountAddDialog.vue";

export default {
  setup() {
    const store = useStore();
    return {
      store,
    };
  },
  name: "LoginPage",
  components: {
    "main-layout": MainLayout,
  },
  data: function () {
    return {
      columns: [
        { label: "名稱", key: "name" },
        { label: "帳號(e-mail)", key: "email" },
        { label: "狀態", key: "status", method: this.accountStatus2Str },
        {
          label: "職稱",
          key: "account_type",
          method: this.accountType2Str,
          width: "30%",
        },
      ],
      accountList: [],
    };
  },
  methods: {
    td(item, column) {
      if (column.method) {
        return column.method(item[column.key]);
      }
      return item[column.key];
    },
    async getAccountList() {
      try {
        this.accountList = await this.store.loading(
          async () => await scdapi.getAccountList()
        );
      } catch (e) {
        this.store.alert(e.message);
      }
    },
    async modifyStatus(id, status) {
      try {
        await this.store.loading(async () => {
          await scdapi.accountModifyStatus(id, status);
          this.accountList = await scdapi.getAccountList();
        });
      } catch (e) {
        this.store.alert(e.message);
      }
    },
    async showAddAccountDialog() {
      var data = toRaw({
        name: null,
        email: null,
        account_type: 3,
        password: null,
      });
      var result = await this.store.dialog(shallowRef(AccountAddDialog), data);
      if (result == 1) {
        return;
      }
      // 確定新增 //
      try {
        await this.store.loading(async () => {
          await scdapi.accountAdd(
            data.name,
            data.email,
            data.account_type,
            data.password
          );
          this.accountList = await scdapi.getAccountList();
        });
      } catch (e) {
        this.store.alert(e.message);
      }
    },
    async showModifyAccountDialog(id) {
      var account = this.accountList.find((e) => e.id == id);
      if (account === undefined) {
        return;
      }
      var data = toRaw({
        name: account.name,
        email: account.email,
        account_type: account.account_type,
      });
      var result = await this.store.dialog(
        shallowRef(AccountModifyDialog),
        data
      );
      if (result == 1) {
        return;
      }
      // 確定修改 //
      try {
        await this.store.loading(async () => {
          await scdapi.accountModifyData(
            account.id,
            data.name,
            data.account_type
          );
          this.accountList = await scdapi.getAccountList();
        });
      } catch (e) {
        this.store.alert(e.message);
      }
    },
    async showChangePasswordDialog(id) {
      var account = this.accountList.find((e) => e.id == id);
      if (account === undefined) {
        return;
      }
      var data = {
        name: account.name,
        email: account.email,
        password: null,
      };
      var result = await this.store.dialog(
        shallowRef(AccountChangePassword),
        data
      );
      if (result == 1) {
        return;
      }
      // 確定修改 //
      try {
        await this.store.loading(async () => {
          await scdapi.accountChangePassword(account.id, data.password);
          await this.store.alert("變更密碼成功", "變更密碼");
          this.accountList = await scdapi.getAccountList();
        });
      } catch (e) {
        this.store.alert(e.message);
      }
    },
    accountStatus2Str(status) {
      return status == 1 ? "啟用" : "停用";
    },
    accountType2Str(accountType) {
      switch (accountType) {
        case 1:
          return "ADMIN";
        case 2:
          return "教師";
        case 3:
          return "學生";
        default:
          return "不明人士";
      }
    },
  },
  mounted: async function () {
    await this.getAccountList();
  },
};
</script>
<style scoped>
.content-zone {
  flex: auto;
  padding: var(--gap-1);
  display: flex;
  flex-direction: row;
}
.content-box {
  flex: auto;
  margin: var(--gap-1);
  border: 1px solid var(--bc1);
  border-radius: var(--radius-1);
  padding: var(--gap-4);
  display: flex;
  flex-direction: column;
}
.content-data {
  overflow-y: auto;
}
.content-label {
  position: absolute;
  padding: var(--gap-1);
  background-color: var(--bc1);
  color: var(--fc1);
  border-radius: var(--radius-1);
}
.button-row {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: var(--gap-2);
}
.add-button {
  color: var(--fc1);
  background-color: var(--bc1);
  padding: var(--gap-1);
  border-radius: var(--radius-1);
  cursor: pointer;
}
.function-column {
  width: 300px;
}
.table * {
  --table-text: #444444;
  --table-bg0: white;
  --table-bg1: #1c6c69;
  --table-bg2: #78c4c4;
  --table-bg3: #c4f0f0;
}
.table {
  width: 100%;
  border-spacing: 1px;
  font-size: 14px;
  background-color: var(--table-bg0);
}
.table th {
  text-align: center;
  background-color: var(--table-bg1);
  color: var(--table-bg0);
  padding: 5px;
  position: sticky;
  top: 1px;
}
.table tr:nth-child(2n) td {
  background-color: var(--table-bg2);
}
.table tr:nth-child(2n + 1) td {
  background-color: var(--table-bg3);
}
.table td {
  text-align: center;
  color: var(--table-text);
  padding: 5px;
}
.table .table-function {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: var(--gap-1);
}
.table .table-button {
  background-color: var(--table-bg1);
  color: var(--table-bg0);
  padding: 5px 16px;
  border-radius: var(--radius-1);
  cursor: pointer;
}
</style>
