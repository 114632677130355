<template>
  <div>
    <div class="board-box" v-if="data != null">
      <div class="hint-time">公告時間：{{ data.create_time }}</div>
      <div class="title-bar">
        <div class="board-label" v-if="data.exam_id !== null">測驗</div>
        <div class="title">{{ data.subject }}</div>
      </div>
      <div class="content" v-html="data.content"></div>
      <div v-if="store.hasPrivilege('c@exam')">
        <span class="exam-button" v-if="data.exam_id !== null" @click="onExam()"
          >點此進入測驗</span
        >
      </div>
      <div v-if="store.hasPrivilege('c@exam_score')">
        <span
          class="score-button"
          v-if="data.exam_id !== null"
          @click="onScore()"
          >評分</span
        >
      </div>
    </div>
  </div>
</template>

<script>
import { useStore } from "@/stores/useStore";

export default {
  setup() {
    const store = useStore();
    return {
      store,
    };
  },
  props: {
    data: null,
  },
  emits: ["on-exam", "on-score"],
  methods: {
    onExam() {
      this.$emit("on-exam", this.data.exam_id);
    },
    onScore() {
      this.$emit("on-score", this.data.exam_id);
    },
  },
};
</script>

<style scoped>
.board-box {
  display: flex;
  flex-direction: column;
  gap: var(--gap-1);
}
.hint-time {
  color: var(--fc4);
  background-color: var(--bc4);
  padding: var(--gap-1);
  font-size: 12px;
  text-align: right;
}
.title-bar {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.board-label {
  color: var(--fc1);
  background-color: var(--bc1);
  font-size: 0.5em;
  padding: 4px 8px;
  border-radius: var(--radius-1);
}
.title {
  flex: auto;
  color: var(--fc7);
  background-color: var(--bc7);
  padding: var(--gap-1);
  font-size: 18px;
}
.content {
  color: var(--fc4);
  background-color: var(--bc4);
  padding: var(--gap-1);
  font-size: 14px;
}
.exam-button {
  display: inline-block;
  color: var(--fc1);
  background-color: var(--bc1);
  padding: var(--gap-1);
  border-radius: var(--radius-1);
  cursor: pointer;
}
.score-button {
  display: inline-block;
  color: var(--fc5);
  background-color: var(--bc5);
  padding: var(--gap-1);
  border-radius: var(--radius-1);
  cursor: pointer;
}
</style>
