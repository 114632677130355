<!-- 側邊欄 -->
<template>
  <div>
    <div class="side-bar" v-if="mainMenu || subMenu.length">
      <div class="side-bar-box">
        <div class="main-menu">{{ mainMenu }}</div>
        <div>
          <div class="sub-menu" v-for="(item, index) in subMenu" :key="index" @click="$emit('side-bar-click', item.value)">
            {{ item.label }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    mainMenu: {
      type: String,
      default: null,
    },
    subMenu: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
};
</script>

<style scoped>
.side-bar {
  width: 200px;
  padding: var(--gap-1);
}
.side-bar-box {
  background-color: var(--bc2);
  color: var(--fc2);
  border-radius: var(--radius-1);
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.5);
  overflow: hidden;
}
.main-menu {
  color: var(--fc5);
  background-color: var(--bc5);
  padding: var(--gap-1);
}
.sub-menu {
  padding: var(--gap-1);
  text-align: right;
  cursor: pointer;
}
</style>