<template>
  <div class="tab-box">
    <div class="table-box" v-if="columns">
      <table class="table">
        <tr>
          <th v-for="(column, index) in columns" :key="index">
            {{ column.label }}
          </th>
        </tr>
        <tr
          :class="selectedUserId == data.user_id ? 'selected-row' : ''"
          v-for="(data, row) in scoreList"
          :key="row"
          @click="selectedUserId = data.user_id"
        >
          <template v-for="(column, index) in columns" :key="index">
            <td v-if="column.html" v-html="td(data, column)"></td>
            <td v-else>
              {{ td(data, column) }}
            </td>
          </template>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import { computed } from "vue";

export default {
  emits: ["update:selectUser"],
  setup(props, { emit }) {
    const selectedUserId = computed({
      get: () => props.selectUser,
      set: val => emit("update:selectUser", val),
    });
    return {selectedUserId};
  },
  props: {
    drugList: null,
    scoreList: null,
    selectUser: null,
  },
  data: function () {
    return {
      columns: [
        { label: "名稱", key: "name" },
        { label: "帳號(email)", key: "email" },
        {
          label: "評分",
          key: "score_status",
          method: (x) => {
            return (x == 1) ? "已評分" : "未評分";
          },
        },
        {
          label: "答案",
          key: "answers",
          html: true,
          method: (answers) => {
            var r = [];
            answers.forEach((a) =>
              r.push(
                "<div>" +
                  a.exam_drug_id +
                  " " +
                  this.drug(a.exam_drug_id).scientific_name +
                  "</div>"
              )
            );
            return r.join("");
          },
        },
      ],
    };
  },
  methods: {
    td(item, column) {
      if (column.method) {
        return column.method(item[column.key]);
      }
      return item[column.key];
    },
    /** 取得單一藥品 */
    drug(exam_drug_id) {
      return this.drugList.find((x) => x.exam_drug_id == exam_drug_id);
    },
  },
};
</script>

<style scoped>
.tab-box {
  padding: var(--gap-4);
}
.table-box {
  width: 100%;
  height: 100%;
}
.table-box {
  width: 100%;
  height: 100%;
  overflow-y: auto;
}
.table {
  width: 100%;
  border-spacing: 1px;
  font-size: 14px;
  background-color: white;
}
.table th {
  text-align: center;
  background-color: #4f618a;
  color: white;
  padding: 5px;
  position: sticky;
  top: 1px;
}
.table tr:nth-child(2n) {
  background-color: #adc1f1;
}
.table tr:nth-child(2n + 1) {
  background-color: #cedaf6;
}
.table td {
  text-align: center;
  color: #444444;
  padding: 5px;
}
.select-button {
  color: #888888;
  cursor: pointer;
}
.select-button.active {
  color: #444444;
}
.select-button:hover {
  color: #000000;
}
/* table 可以選擇 */
.table td {
  cursor: pointer;
}
.selected-row td {
  border-top: 2px solid var(--bc5);
  border-bottom: 2px solid var(--bc5);
}
.selected-row td:first-child {
  border-left: 2px solid var(--bc5);
}
.selected-row td:last-child {
  border-right: 2px solid var(--bc5);
}
</style>
