<template>
  <div>
    <div class="backgroud">
      <div class="board-box">
        <question-overview
          v-for="question in questions"
          :key="question.id"
          :question="question"
          @on-question="(question_id) => $router.push('/question/' + exam_id + '/' + question_id)"
        ></question-overview>
        <div class="end-exam-button" @click="$router.push('/home')">結束測驗</div>
      </div>
    </div>
  </div>
</template>

<script>
import scdapi from "@/scdapi.js";
import { useStore } from "@/stores/useStore";
import QuestionOverview from "@/components/QuestionOverview.vue";

export default {
  setup() {
    const store = useStore();
    return {
      store,
    };
  },
  components: {
    "question-overview": QuestionOverview,
  },
  data: function () {
    return {
      questions: [],
    };
  },
  computed: {
    exam_id() {
      return this.$route.params.exam_id;
    },
  },
  mounted: async function () {
    try {
      var result = await scdapi.getExam(this.exam_id);
      this.questions = result.questions;
    } catch (e) {
      this.store.alert(e.message);
    }
  },
};
</script>

<style scoped>
.backgroud {
  background-color: var(--bc2);
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: center;
}
.board-box {
  position: relative;
  flex: none;
  width: 50%;
  background-color: var(--bc4);
  margin: var(--gap-2);
  padding: var(--gap-4);
  display: flex;
  flex-direction: column;
  gap: var(--gap-4);
  overflow-y: auto;
}
.end-exam-button {
  position: absolute;
  top: 16px;
  right: 32px;
  font-size: 12px;
  padding: var(--gap-1);
  background-color: var(--bc6);
  color: var(--fc-6);
  border-radius: var(--radius-1);
  cursor: pointer;
}
</style>
