<!-- 主選單 -->
<template>
  <div>
    <div class="menu-bar">
      <div class="menu-item" v-if="store.hasPrivilege('c@border_list')" @click="$router.push('/home');">最新消息</div>
      <div class="menu-item" v-if="store.hasPrivilege('c@border_manage')" @click="$router.push('/border_manage');">公告管理</div>
      <div class="menu-item" v-if="store.hasPrivilege('c@question_manage')" @click="$router.push('/exam_list');">問題管理</div>
      <div class="menu-item" v-if="store.hasPrivilege('c@account_list')" @click="$router.push('/account');">帳號管理</div>
      <div class="menu-item" v-if="store.hasPrivilege('c@response')" @click="$router.push('/response');">反饋意見</div>
      <div class="menu-item" v-if="store.hasPrivilege('c@list_response')" @click="$router.push('/list_response');">查看反饋意見</div>
    </div>
  </div>
</template>

<script>
import { useStore } from "@/stores/useStore";

export default {
  setup() {
    const store = useStore();
    return {
      store,
    };
  },
};
</script>

<style scoped>
.menu-bar {
  color: var(--fc3);
  background-color: var(--bc3);
  font-size: 20px;
  height: 32px;
  padding: 0 var(--gap-4);
  display: flex;
  flex-direction: row;
  gap: var(--gap-2);
  align-items: center;
}
.menu-item {
  cursor: pointer;
}
</style>