<template>
  <div class="tab-box">
    <table class="table" v-if="patient && functionMode == 0">
      <tr>
        <th>姓名</th>
        <td>{{ patientData.name }}</td>
        <th>年齡</th>
        <td>{{ patientData.birthym }}</td>
        <th>性別</th>
        <td>{{ patientData.sex_code }}</td>
        <th>病歷號</th>
        <td>{{ patientData.person_no }}</td>
      </tr>
      <tr>
        <th>身高</th>
        <td>{{ patientData.height.value }}</td>
        <th>體重</th>
        <td>{{ patientData.weight.value }}</td>
        <th>SCr</th>
        <td>{{ patientData.scr.value }}({{ patientData.scr.monthDate }})</td>
        <th>CCr</th>
        <td>{{ patientData.ccr.value }}</td>
      </tr>
    </table>
    <table class="table" v-if="patient && functionMode == 1">
      <tr>
        <th>姓名</th>
        <td><input v-model="patientData.name" /></td>
      </tr>
      <tr>
        <th>年齡</th>
        <td><input v-model="patientData.birthym" /></td>
      </tr>
      <tr>
        <th>性別</th>
        <td><input v-model="patientData.sex_code" /></td>
      </tr>
      <tr>
        <th>病歷號</th>
        <td><input v-model="patientData.person_no" /></td>
      </tr>
    </table>
  </div>
</template>

<script>
import { computed } from "vue";

export default {
  setup(props, { emit }) {
    const patientData = computed({
      get: () => props.patient,
      set: (val) => emit("update:patient", val),
    });
    return { patientData };
  },
  props: {
    functionMode: {
      type: Number,
      default: 0,
    },
    patient: null,
  },
};
</script>

<style scoped>
.tab-box {
  padding: var(--gap-4);
}
.table {
  width: 100%;
  border-spacing: 1px;
  font-size: 14px;
  background-color: white;
}
.table th {
  text-align: center;
  background-color: #4f618a;
  color: white;
  padding: 5px;
  position: sticky;
  top: 1px;
}
.table td {
  text-align: center;
  color: #444444;
  background-color: #cedaf6;
  padding: 5px;
}
.table input {
  height: 28px;
  width: 98%;
}
</style>
