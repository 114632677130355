<template>
  <div class="tab-box-outer">
    <div class="table-box" ref="tableBox">
      <table class="table" v-if="lab">
        <tr>
          <th class="sort-button" @click="sortType='name'">
            項目名稱<i class="bi" :class="'bi-caret-down' + (sortType=='name' ? '-fill' : '')" />
          </th>
          <th>單位</th>
          <th>數值</th>
          <th class="sort-button" @click="sortType='datetime'">
            日期<i class="bi" :class="'bi-caret-down' + (sortType=='datetime' ? '-fill' : '')" />
          </th>
          <th>時間</th>
          <th v-if="functionMode == 1">功能</th>
        </tr>
        <template v-if="functionMode == 0">
          <tr v-for="(data, row) in sorted(lab)" :key="row">
            <td>{{ measure(data.measure_type_id).name }}</td>
            <td>{{ measure(data.measure_type_id).unit }}</td>
            <td>{{ data.value }}</td>
            <td>{{ data.createdate }}</td>
            <td>{{ data.createtime }}</td>
          </tr>
        </template>
        <template v-if="functionMode == 1">
          <tr v-for="(data, row) in lab" :key="row">
            <td>
              <select v-model="data.measure_type_id">
                <option v-for="m in measureType" :key="m.id" :value="m.id">
                  {{ m.name }}
                </option>
              </select>
            </td>
            <td>{{ measure(data.measure_type_id).unit }}</td>
            <td><input type="text" v-model="data.value" /></td>
            <td>
              <input type="date" v-model="data.createdate" />
            </td>
            <td>
              <input type="time" step="1" v-model="data.createtime" />
            </td>
            <td>
              <div class="table-button" @click="removeMeasure(row)">刪除</div>
            </td>
          </tr>
        </template>
      </table>
    </div>
    <table class="table" v-if="lab && functionMode == 1">
      <tr>
        <th>項目名稱</th>
        <th>單位</th>
        <th>數值</th>
        <th>日期</th>
        <th>時間</th>
        <th>功能</th>
      </tr>
      <tr>
        <td>
          <select v-model="addData.measure_type_id">
            <option v-for="m in measureType" :key="m.id" :value="m.id">
              {{ m.name }}
            </option>
          </select>
        </td>
        <td>{{ measure(addData.measure_type_id).unit }}</td>
        <td><input type="text" v-model="addData.value" /></td>
        <td>
          <input type="date" v-model="addData.createdate" />
        </td>
        <td>
          <input type="time" step="1" v-model="addData.createtime" />
        </td>
        <td>
          <div class="table-button" @click="addMeasure()">新增</div>
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
import { computed, nextTick } from "vue";

export default {
  setup(props, { emit }) {
    const lab = computed({
      get: () => props.labList,
      set: (val) => emit("update:labList", val),
    });
    return { lab };
  },
  props: {
    functionMode: {
      type: Number,
      default: 0,
    },
    labList: null,
    measureType: null,
  },
  data: function () {
    return {
      columns: [
        {
          label: "項目名稱",
          method: (row) => this.measure(row.measure_type_id).name,
          type: "select",
        },
        {
          label: "單位",
          method: (row) => this.measure(row.measure_type_id).unit,
        },
        { label: "數值", key: "value", type: "text" },
        { label: "日期", key: "createdate", type: "date" },
        { label: "時間", key: "createtime", type: "time" },
      ],
      addData: {
        measure_type_id: 1,
        value: 0,
        createtime: null,
      },
      sortType: 'datetime',
    };
  },
  methods: {
    measure(measureTypeId) {
      return this.measureType.find((x) => x.id == measureTypeId);
    },
    removeMeasure(row) {
      this.lab.splice(row, 1);
    },
    async addMeasure() {
      this.lab.push({
        measure_type_id: this.addData.measure_type_id,
        value: this.addData.value,
        createdate: this.addData.createdate,
        createtime: this.addData.createtime,
      });
      const tableBox = this.$refs.tableBox;
      console.log(tableBox);
      await nextTick();
      tableBox.scrollTop = tableBox.scrollHeight;
    },
    sortByDatetime(a, b) {
      if (a.createdate > b.createdate) return 1;
      if (a.createdate < b.createdate) return -1;
      if (a.createtime > b.createtime) return 1;
      if (a.createtime < b.createtime) return -1;
      if (this.measure(a.measure_type_id).name > this.measure(b.measure_type_id).name) return 1;
      if (this.measure(a.measure_type_id).name < this.measure(b.measure_type_id).name) return -1;
      return 0;
    },
    sortByName(a, b) {
      if (this.measure(a.measure_type_id).name > this.measure(b.measure_type_id).name) return 1;
      if (this.measure(a.measure_type_id).name < this.measure(b.measure_type_id).name) return -1;
      if (a.createdate > b.createdate) return 1;
      if (a.createdate < b.createdate) return -1;
      if (a.createtime > b.createtime) return 1;
      if (a.createtime < b.createtime) return -1;
      return 0;
    },
    sorted(data) {
      var result = data.sort(this.sortType == 'datetime' ? this.sortByDatetime : this.sortByName);
      console.log(result);
      return result;
    }
  },
};
</script>

<style scoped>
.tab-box-outer {
  padding: var(--gap-4);
  display: flex;
  flex-direction: column;
  gap: var(--gap-2);
}
.table-box {
  flex: auto;
  width: 100%;
  overflow-y: auto;
}
/** css about table */
.table * {
  --table-text: #444444;
  --table-bg0: white;
  --table-bg1: #4f618a;
  --table-bg2: #adc1f1;
  --table-bg3: #cedaf6;
}
.table {
  width: 100%;
  border-spacing: 1px;
  font-size: 14px;
  background-color: var(--table-bg0);
}
.table th {
  text-align: center;
  background-color: var(--table-bg1);
  color: var(--table-bg0);
  padding: 5px;
  position: sticky;
  top: 1px;
}
.table tr:nth-child(2n) td {
  background-color: var(--table-bg2);
}
.table tr:nth-child(2n + 1) td {
  background-color: var(--table-bg3);
}
.table td {
  text-align: center;
  color: var(--table-text);
  padding: 5px;
}
.table input {
  width: 100%;
  box-sizing: border-box;
  height: 28px;
  background-color: transparent;
  border: 1px solid var(--table-text);
}
.table select {
  width: 100%;
  box-sizing: border-box;
  height: 28px;
  background-color: transparent;
}
.table .table-button {
  background-color: var(--table-bg1);
  color: var(--table-bg0);
  padding: 5px 16px;
  border-radius: var(--radius-1);
  cursor: pointer;
}
.table .sort-button {
  cursor: pointer;
}
</style>
