<template>
  <div>
    <div class="backgroud">
      <div class="question-zone">
        <tab-bar
          class="question-tab-bar"
          :tabs="questionTab"
          :activeTab="activeQuestionTab"
          @tab-click="questionTabClick"
        ></tab-bar>
        <div class="question-content">
          <question-tab-question
            v-if="patient && activeQuestionTab == 5"
            :question="question"
          ></question-tab-question>
          <question-tab-drug-edit
            v-if="patient && activeQuestionTab == 0"
            :drugList="exam_drug_list"
            :answers="[]"
          ></question-tab-drug-edit>
          <question-tab-lab
            v-if="patient && activeQuestionTab == 1"
            :functionMode="1"
            v-model:labList="patient.measures"
            :measureType="measureType"
          ></question-tab-lab>
          <question-tab-patient
            v-if="patient && activeQuestionTab == 2"
            :functionMode="1"
            v-model:patientContent="patient.content"
          ></question-tab-patient>
          <question-tab-patient
            v-if="patient && activeQuestionTab == 3"
            :functionMode="1"
            v-model:patientContent="patient.history"
          ></question-tab-patient>
          <question-tab-basic
            v-if="patient && activeQuestionTab == 4"
            :functionMode="1"
            v-model:patient="patient"
          ></question-tab-basic>
        </div>
        <div class="action-zone">
          <div class="action-button" @click="saveQuestion">儲存</div>
          <div class="action-button" @click="abortQuestion">回列表</div>
        </div>
      </div>
      <div class="answer-zone">
        <!-- <tab-bar
          class="answer-tab-bar"
          tabAlign="left"
          :tabs="answerTab"
          :activeTab="activeAnswerTab"
          @tab-click="answerTabClick"
        ></tab-bar>
        <div class="answer-content"></div> -->
      </div>
      <div class="action-zone"></div>
    </div>
  </div>
</template>

<script>
import scdapi from "@/scdapi.js";
import { useStore } from "@/stores/useStore";
import TabBar from "@/components/TabBar.vue";
import QuestionTabPatient from "@/components/QuestionTabPatient.vue";
import QuestionTabDrugEdit from "@/components/QuestionTabDrugEdit.vue";
import QuestionTabLab from "@/components/QuestionTabLab.vue";
import QuestionTabBasic from "@/components/QuestionTabBasic.vue";
import QuestionTabQuestion from "@/components/QuestionTabQuestion.vue";

export default {
  setup() {
    const store = useStore();
    return {
      store,
    };
  },
  components: {
    "tab-bar": TabBar,
    "question-tab-patient": QuestionTabPatient,
    "question-tab-drug-edit": QuestionTabDrugEdit,
    "question-tab-lab": QuestionTabLab,
    "question-tab-basic": QuestionTabBasic,
    "question-tab-question": QuestionTabQuestion,
  },
  data: function () {
    return {
      questionTab: [
        { label: "題目概述", value: 5 },
        { label: "處方", value: 0 },
        { label: "LAB", value: 1 },
        { label: "本次診治情形", value: 2 },
        { label: "病史", value: 3 },
        { label: "基本資料", value: 4 },
      ],
      activeQuestionTab: 5,
      activeAnswerTab: null,
      exam_id: null,
      question: null,
      patient: null,
      exam_drug_list: [],
      measureType: [],
    };
  },
  methods: {
    questionTabClick(value) {
      this.activeQuestionTab = value;
    },
    async saveQuestion() {
      try {
        // 檢查測量資料 //
        for (let i = 0; i < this.patient.measures.length; i ++) {
          const measure = this.patient.measures[i];
          if (measure.createdate == null || measure.createdate.length != 10) {
            await this.store.alert("LAB中，部分測量項目沒有填寫日期，請填寫", "儲存失敗");
            return;
          }
        }
        
        // 檢查處方資料 //
        for (let i = 0; i < this.exam_drug_list.length; i ++) {
          const drug = this.exam_drug_list[i];
          if (!drug.startdatetime) {
            await this.store.alert("處方中，有部分 開方日期 未填寫，請填寫", "儲存失敗");
            return;
          }
          if (!drug.scientific_name) {
            await this.store.alert("處方中，有部分 藥品名稱 未填寫，請填寫", "儲存失敗");
            return;
          }
          if (Number.isNaN(Number.parseFloat(drug.orderdose))) {
            await this.store.alert("處方中，有部分 劑量 未填寫 或 不是數字，請填寫", "儲存失敗");
            return;
          }
          if (!drug.doseunit) {
            await this.store.alert("處方中，有部分 單位 未填寫，請填寫", "儲存失敗");
            return;
          }
          if (!drug.repeatpatterncode) {
            await this.store.alert("處方中，有部分 頻率 未填寫，請填寫", "儲存失敗");
            return;
          }
          if (!drug.routecode) {
            await this.store.alert("處方中，有部分 途徑 未填寫，請填寫", "儲存失敗");
            return;
          }
        }
        // 分解重組資料 //
        const birthymPart = this.patient.birthym.split('y');
        this.patient.fix_year = birthymPart[0];
        this.patient.fix_month = birthymPart[1].split('m')[0];

        await this.store.loading(
          async () =>
            await scdapi.saveQuestion(
              this.question,
              this.patient,
              this.exam_drug_list
            )
        );
        this.store.alert("儲存成功");
        // 重新讀取 
      } catch (e) {
        await this.store.alert(e.message, "儲存失敗");
      }
      this.getQuestion();
    },
    async getQuestion() {
      try {
        // 讀取題目 //
        var result = await this.store.loading(
          async () =>
            await scdapi.getQuestion(this.question_id)
        );
        this.exam_id = result.exam_id;
        this.question = result.question;
        this.patient = result.patient;
        this.exam_drug_list = result.exam_drug_list;
      } catch (e) {
        this.store.alert(e.message);
      }
    },
    async abortQuestion() {
      if (
        (await this.store.confirm(
          "編輯異動將不會儲存，是否要離開？",
          "確認離開"
        )) == 0
      ) {
        this.$router.push("/exam_list");
      }
    },
  },
  computed: {
    question_id() {
      return this.$route.params.question_id;
    },
  },
  mounted: async function () {
    try {
      // 讀取共同資料 //
      this.measureType = await scdapi.getMeasureType();
    } catch (e) {
      this.store.alert(e.message);
    }
    // 讀取題目 //
    this.getQuestion();
  },
};
</script>

<style scoped>
.backgroud {
  background-color: var(--bc2);
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: row;
}
.question-zone {
  flex: auto;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding: var(--gap-2);
  padding-right: var(--gap-1);
  display: flex;
  flex-direction: column;
}
.answer-zone {
  flex: auto;
  box-sizing: border-box;
  width: 0%;
  height: 100%;
  padding: var(--gap-2);
  padding-left: var(--gap-1);
  display: flex;
  flex-direction: column;
}
.question-tab-bar {
  flex: none;
  height: 36px;
}
.answer-tab-bar {
  flex: none;
  height: 36px;
}
.question-content {
  flex: auto;
  background-color: white;
  display: flex;
  flex-direction: column;
  height: 1px;
  background-color: white;
}
.question-content > * {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
}
.answer-content {
  flex: auto;
  background-color: white;
  height: 1px;
}
.answer-content > * {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
}
.action-zone {
  position: fixed;
  top: var(--gap-1);
  left: var(--gap-2);
  display: flex;
  flex-direction: row;
  gap: var(--gap-1);
}
.action-button {
  color: var(--bc7);
  background-color: var(--fc7);
  padding: var(--gap-1);
  border-radius: var(--radius-1);
  cursor: pointer;
}
</style>
