<template>
  <div>
    <div class="loading">Loading ...</div>
  </div>
</template>

<script>
export default {
};
</script>

<style scoped>
.loading {
    color: white;
    font-size: 24px;
}
</style>