<template>
  <div>
    <main-layout>
      <div class="content-zone">
        <div class="content-box">
          <div
            class="content-data"
            ref="contentData"
            v-if="randerBoardId === null"
          >
            <board-overview
              v-for="board in boardList"
              :key="board.id"
              :data="board"
              @click="boardClick(board.id)"
            ></board-overview>
          </div>
          <div
            class="content-data"
            ref="contentData"
            v-if="randerBoardId !== null"
          >
            <board-view
              :data="board"
              @on-exam="(exam_id) => $router.push('/exam/' + exam_id)"
              @on-score="(exam_id) => $router.push('/exam_score/' + exam_id)"
            ></board-view>
          </div>
        </div>
        <div class="content-label">{{ label }}</div>
        <div class="content-right">
          <div
            class="content-close-button"
            v-if="randerBoardId !== null"
            @click="randerBoardId = null"
          >
            返回列表
          </div>
        </div>
      </div>
    </main-layout>
  </div>
</template>

<script>
import scdapi from "@/scdapi.js";
import MainLayout from "@/layouts/MainLayout.vue";
import BoardOverview from "@/components/BoardOverview.vue";
import BoardView from "@/components/BoardView.vue";
import { useStore } from "@/stores/useStore";

export default {
  setup() {
    const store = useStore();
    return {
      store,
    };
  },
  data: function () {
    return {
      label: "最新消息", // 目前顯示的標題 //
      boardList: [],
      gettingBoardList: false, // 正在讀取列表中，避免重複進入 //
      lastBoardIndex: null,

      randerBoardId: null, // 正在顯示的單一則公告，null則表示為顯示全部公告 //
    };
  },
  name: "LoginPage",
  components: {
    "main-layout": MainLayout,
    "board-overview": BoardOverview,
    "board-view": BoardView,
  },
  methods: {
    /** 監聽卷軸捲動 */
    beginBoardWatch: async function () {
      const boardDom = this.$refs.contentData;
      const self = this;
      boardDom.addEventListener("scroll", function (event) {
        var element = event.target;
        const load_offset = 100; // 差 100 pixel 開始 load //
        if (
          element.scrollHeight - element.clientHeight - element.scrollTop <=
          load_offset
        ) {
          self.getBoardList();
        }
      });
    },
    /** 點擊一則公告 */
    boardClick: function(boardId) {
      if (this.store.hasPrivilege('c@border_detail')) {
        this.randerBoardId = boardId;
      }
    },
    getBoardList: async function () {
      if (this.gettingBoardList) {
        return;
      }
      this.gettingBoardList = true;
      if (this.lastBoardIndex == scdapi.END_BOARD_ID) {
        return;
      }
      var result = await scdapi.getBoardList(this.lastBoardIndex);
      this.lastBoardIndex =
        result.length == 0 ? scdapi.END_BOARD_ID : result[result.length - 1].id;
      result.forEach((e) => {
        this.boardList.push(e);
      });
      this.gettingBoardList = false;
    },
  },
  computed: {
    /** 依照目前顯示的看板編號(randerBoardId)，回傳看板資料 */
    board: function () {
      for (var i = 0; i < this.boardList.length; i++) {
        if (this.boardList[i].id == this.randerBoardId) {
          return this.boardList[i];
        }
      }
      return null;
    },
  },
  mounted: async function () {
    try {
      this.lastBoardIndex = scdapi.START_BOARD_ID;
      await this.getBoardList();
      await this.beginBoardWatch();
    } catch (e) {
      this.store.alert(e.message);
    }
  },
};
</script>

<style scoped>
.content-zone {
  flex: auto;
  padding: var(--gap-1);
  display: flex;
  flex-direction: row;
}
.content-box {
  flex: auto;
  margin: var(--gap-1);
  border: 1px solid var(--bc1);
  border-radius: var(--radius-1);
  padding: var(--gap-4);
  display: flex;
  flex-direction: column;
}
.content-data {
  flex: auto;
  display: flex;
  flex-direction: column;
  padding: 0 var(--gap-4);
  gap: var(--gap-4);
  overflow-y: auto;
}
.content-label {
  position: absolute;
  padding: var(--gap-1);
  background-color: var(--bc1);
  color: var(--fc1);
  border-radius: var(--radius-1);
}
.content-right {
  position: absolute;
  right: 32px;
}
.content-close-button {
  position: relative;
  top: 16px;
  font-size: 12px;
  padding: var(--gap-1);
  background-color: var(--bc6);
  color: var(--fc-6);
  border-radius: var(--radius-1);
  cursor: pointer;
}
</style>
