import { createApp } from 'vue'
import { createPinia } from 'pinia';
import App from './App.vue'
import { router } from './router.js';
import 'normalize.css/normalize.css';
import "bootstrap-icons/font/bootstrap-icons.css";
import './scd.css';

const app = createApp(App);
app.use(router);
const pinia = createPinia();
app.use(pinia);

app.mount('#app');
