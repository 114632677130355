<template>
  <div class="page">
    <header-bar></header-bar>
    <menu-bar></menu-bar>
    <div class="main-content">
      <side-bar
        :main-menu="sideBarMenu?.mainMenu"
        :sub-menu="sideBarMenu?.subMenu"
        @sideBarClick="(data) => $emit('side-bar-click', data)"
      ></side-bar>
      <slot></slot>
    </div>
  </div>
</template>

<script>
import HeaderBar from "@/components/HeaderBar.vue";
import MenuBar from "@/components/MenuBar.vue";
import SideBar from "@/components/SideBar.vue";

export default {
  props: {
    sideBarMenu: null,
  },
  name: "MainLayout",
  components: {
    "header-bar": HeaderBar,
    "menu-bar": MenuBar,
    "side-bar": SideBar,
  },
};
</script>

<style scoped>
.page {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
}
.main-content {
  flex: auto;
  display: flex;
  flex-direction: row;
  min-height: 100px;
}
</style>