<template>
  <div>
    <div class="backgroud">
      <div class="question-zone">
        <tab-bar
          class="question-tab-bar"
          :tabs="questionTab"
          :activeTab="activeQuestionTab"
          @tab-click="questionTabClick"
        ></tab-bar>
        <div class="question-content">
          <question-tab-drug
            v-if="patient && activeQuestionTab == 0"
            :drugList="exam_drug_list"
            :answers="answers"
            @selectDrug="addAnswer"
          ></question-tab-drug>
          <question-tab-lab
            v-if="patient && activeQuestionTab == 1"
            :labList="patient.measures"
            :measureType="measureType"
          ></question-tab-lab>
          <question-tab-patient
            v-if="patient && activeQuestionTab == 2"
            :patientContent="patient.content"
          ></question-tab-patient>
          <question-tab-patient
            v-if="patient && activeQuestionTab == 3"
            :patientContent="patient.history"
          ></question-tab-patient>
          <question-tab-basic
            v-if="patient && activeQuestionTab == 4"
            :patient="patient"
          ></question-tab-basic>
        </div>
      </div>
      <div class="answer-zone">
        <tab-bar
          class="answer-tab-bar"
          tabAlign="left"
          :tabs="answerTab"
          :activeTab="activeAnswerTab"
          @tab-click="answerTabClick"
        ></tab-bar>
        <div class="answer-content">
          <answer-tab
            :examDrug="examDrug(activeAnswerTab)"
            @remove:answer="removeAnswer"
            v-model:answer="answer"
          ></answer-tab>
        </div>
      </div>
      <div class="action-zone">
        <div class="action-button" @click="saveAnswer">儲存答案</div>
        <!-- <div class="action-button">前一題</div> -->
        <div class="action-button" @click="toPageExam">回題目列表</div>
        <!-- <div class="action-button">下一題</div> -->
      </div>
    </div>
  </div>
</template>

<script>
import scdapi from "@/scdapi.js";
import { useStore } from "@/stores/useStore";
import TabBar from "@/components/TabBar.vue";
import QuestionTabPatient from "@/components/QuestionTabPatient.vue";
import QuestionTabDrug from "@/components/QuestionTabDrug.vue";
import QuestionTabLab from "@/components/QuestionTabLab.vue";
import QuestionTabBasic from "@/components/QuestionTabBasic.vue";
import AnswerTab from "@/components/AnswerTab.vue";

export default {
  setup() {
    const store = useStore();
    return {
      store,
    };
  },
  components: {
    "tab-bar": TabBar,
    "question-tab-patient": QuestionTabPatient,
    "question-tab-drug": QuestionTabDrug,
    "question-tab-lab": QuestionTabLab,
    "question-tab-basic": QuestionTabBasic,
    "answer-tab": AnswerTab,
  },
  data: function () {
    return {
      questionTab: [
        { label: "處方", value: 0 },
        { label: "LAB", value: 1 },
        { label: "本次診治情形", value: 2 },
        { label: "病史", value: 3 },
        { label: "基本資料", value: 4 },
      ],
      activeQuestionTab: 0,
      activeAnswerTab: null,
      patient: null,
      exam_drug_list: [],
      measureType:[],
      answers: [],
      answerModifed: false, //答案是否修改過(影響是否要儲存) //
      saveAnswerTimer: null, // 定時儲存答案計時器 //
    };
  },
  methods: {
    questionTabClick(value) {
      this.activeQuestionTab = value;
    },
    answerTabClick(value) {
      this.activeAnswerTab = value;
    },
    examDrug(examDrugId) {
      for (var i = 0; i < this.exam_drug_list.length; i++) {
        if (this.exam_drug_list[i].exam_drug_id == examDrugId) {
          return this.exam_drug_list[i];
        }
      }
      return null;
    },
    removeAnswer(exam_drug_id) {
      console.log(exam_drug_id);
      var index = this.answers.findIndex((x) => x.exam_drug_id == exam_drug_id);
      if (index != -1) {
        // 先移動 activeAnswerTab 的位置 //
        if (index < this.answers.length - 1) {
          this.activeAnswerTab = this.answers[index + 1].exam_drug_id;
        } else {
          if (index > 0) {
            this.activeAnswerTab = this.answers[index - 1].exam_drug_id;
          } else {
            this.activeAnswerTab = null;
          }
        }
        this.answers.splice(index, 1);
      }
    },
    addAnswer(exam_drug_id) {
      // 查詢是否已經有此答案 //
      var index = this.answers.findIndex((x) => x.exam_drug_id == exam_drug_id);
      if (index == -1) {
        this.answers.push({
          exam_drug_id: exam_drug_id,
          reason_code: 0,
          reason_content: "",
          sug_code: 0,
          sug_doseunit: "",
          sug_orderdose: 0,
          sug_repeatpatterncode: "",
          sug_routecode: "",
          sug_scientific_name: "",
        });
      }
      this.activeAnswerTab = exam_drug_id;
    },
    toPageExam() {
      this.saveAnswer();
      this.$router.push("/exam/" + this.exam_id);
    },
    async saveAnswer() {
      // 儲存答案 //
      if (this.answerModifed) {
        console.log("saveAnswer");
        this.answerModifed = false;
        scdapi.saveAnswer(this.question_id, this.answers);
      }
    },
    startTimer() {
      this.saveAnswerTimer = setInterval(this.saveAnswer, 10000);
    },
    endTimer() {
      if (this.saveAnswerTimer) {
        clearInterval(this.saveAnswerTimer);
      }
    },
  },
  computed: {
    exam_id() {
      return this.$route.params.exam_id;
    },
    question_id() {
      return this.$route.params.question_id;
    },
    answerTab() {
      var result = [];
      this.answers.forEach((e) => {
        const drug = this.examDrug(e.exam_drug_id);
        if (drug) {
          result.push({
            label: e.exam_drug_id + " " + drug.scientific_name,
            value: e.exam_drug_id,
          });
        }
      });
      return result;
    },
    answer() {
      for (var i = 0; i < this.answers.length; i++) {
        if (this.answers[i].exam_drug_id == this.activeAnswerTab) {
          return this.answers[i];
        }
      }
      return null;
    },
  },
  watch: {
    answers: {
      deep: true,
      handler: function () {
        this.answerModifed = true;
      },
    },
  },
  mounted: async function () {
    try {
      // 讀取共同資料 //
      this.measureType = await scdapi.getMeasureType();
      // 讀取題目 //
      var result = await scdapi.getQuestion(this.question_id);
      this.patient = result.patient;
      this.exam_drug_list = result.exam_drug_list;
      // 讀取答案 //
      var answers = await scdapi.getAnswer(this.question_id);
      this.answers = answers;
      // 如果有填寫答案，預設切換到第一個 //
      if (this.answers.length > 0) {
        this.activeAnswerTab = this.answers[0].exam_drug_id;
      }
      // 清除自動儲存機制 //
      this.answerModifed = false;
      this.startTimer();
    } catch (e) {
      await this.store.alert(e.message);
      this.toPageExam();
    }
  },
  beforeUnmount: function () {
    this.endTimer();
  },
};
</script>

<style scoped>
.backgroud {
  background-color: var(--bc2);
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: row;
}
.question-zone {
  flex: auto;
  box-sizing: border-box;
  width: 50%;
  height: 100%;
  padding: var(--gap-2);
  padding-right: var(--gap-1);
  display: flex;
  flex-direction: column;
}
.answer-zone {
  flex: auto;
  box-sizing: border-box;
  width: 50%;
  height: 100%;
  padding: var(--gap-2);
  padding-left: var(--gap-1);
  display: flex;
  flex-direction: column;
}
.question-tab-bar {
  flex: none;
  height: 36px;
}
.answer-tab-bar {
  flex: none;
  height: 36px;
}
.question-content {
  flex: auto;
  background-color: white;
  display: flex;
  flex-direction: column;
  height: 1px;
  background-color: white;
}
.question-content > * {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
}
.answer-content {
  flex: auto;
  background-color: white;
  height: 1px;
}
.answer-content > * {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
}
.action-zone {
  position: fixed;
  top: var(--gap-1);
  right: var(--gap-2);
  display: flex;
  flex-direction: row;
  gap: var(--gap-1);
}
.action-button {
  color: var(--fc1);
  background-color: var(--bc1);
  padding: var(--gap-1);
  border-radius: var(--radius-1);
  cursor: pointer;
}
</style>
