<template>
  <div class="tab-box">
    <div class="table-box" v-if="columns">
      <table class="table">
        <tr>
          <th v-for="(column, index) in columns" :key="index">
            {{ column.label }}
          </th>
          <th>選取</th>
        </tr>
        <template v-for="(data, row) in drugList" :key="row">
          <tr :row="(row % 2) ? 'odd' : 'even'">
          <template v-for="(column, index) in columns" :key="index">
            <td v-if="column.html" v-html="td(data, column)" :rowspan="data.memo && (index == 0 || index == 10) ? 2 : 1"></td>
            <td v-else :rowspan="data.memo && (index == 0 || index == 10) ? 2 : 1">
              {{ td(data, column) }}
            </td>
          </template>
          <td :rowspan="data.memo ? 2 : 1">
            <div v-if="inAnswer(data.exam_drug_id)" class="select-button active" @click="selectDrug(data.exam_drug_id)">
              <i class="bi bi-check-square-fill"></i>
            </div>
            <div v-else class="select-button" @click="selectDrug(data.exam_drug_id)">
              <i class="bi bi-square"></i>
            </div>
          </td>
          </tr>
          <tr v-if="data.memo" :row="(row % 2) ? 'odd' : 'even'">
            <td colspan="9" class="memo">{{ data.memo }}</td>
          </tr>
        </template>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  emits: ["selectDrug"],
  setup() {},
  props: {
    drugList: null,
    answers: null,
  },
  data: function () {
    return {
      columns: [
        { label: "序號", key: "exam_drug_id" },
        {
          label: "開方日",
          key: "startdatetime",
          method: function (x) {
            return x ? x.substr(0, 10) : "";
          },
        },
        { label: "處方號", key: "exam_drugorder_id" },
        { label: "藥品名稱", key: "scientific_name" },
        { label: "劑量", key: "orderdose" },
        { label: "單位", key: "doseunit" },
        { label: "頻率", key: "repeatpatterncode" },
        { label: "途徑", key: "routecode" },
        {
          label: "停用日",
          key: "enddatetime",
          method: function (x) {
            return x ? x.substr(0, 10) : "";
          },
        },
        {
          label: "OPD",
          key: "opd_medication",
          method: function (x) {
            return x ? "是" : "否";
          },
        },
      ],
    };
  },
  methods: {
    td(item, column) {
      if (column.method) {
        return column.method(item[column.key]);
      }
      return item[column.key];
    },
    inAnswer(exam_drug_id) {
      // 回傳此藥品是否在答案中 //
      return (
        this.answers.findIndex((x) => x.exam_drug_id == exam_drug_id) != -1
      );
    },
    selectDrug(exam_drug_id) {
      this.$emit("selectDrug", exam_drug_id);
    },
  },
};
</script>

<style scoped>
.tab-box {
  padding: var(--gap-4);
}
.table-box {
  width: 100%;
  height: 100%;
}
.table-box {
  width: 100%;
  height: 100%;
  overflow-y: auto;
}
.table {
  width: 100%;
  border-spacing: 1px;
  font-size: 14px;
  background-color: white;
}
.table th {
  text-align: center;
  background-color: #4f618a;
  color: white;
  padding: 5px;
  position: sticky;
  top: 1px;
}
.table tr[row=even] td {
  background-color: #adc1f1;
}
.table tr[row=odd] td {
  background-color: #cedaf6;
}
.table td {
  text-align: center;
  color: #444444;
  padding: 5px;
}
.table .memo {
  text-align: left;
}
.select-button {
  color: #888888;
  cursor: pointer;
}
.select-button.active {
  color: #444444;
}
.select-button:hover {
  color: #000000;
}
</style>
