<template>
  <div>
    <scd-dialog :data="dialogData">
      <div class="dialog-content">
        <div class="item-row">
          <div class="item-title">名稱</div>
          <input class="item-value" type="text" v-model="accountData.name" />
        </div>
        <div class="item-row">
          <div class="item-title">帳號(email)</div>
          <div class="item-value">{{ accountData.email }}</div>
        </div>
        <div class="item-row">
          <div class="item-title">職稱</div>
          <select class="item-value" v-model="accountData.account_type">
            <option
              v-for="accountType in accountTypeMapping"
              :key="accountType.value"
              :value="accountType.value"
            >
              {{ accountType.label }}
            </option>
          </select>
        </div>
      </div>
    </scd-dialog>
  </div>
</template>

<script>
import ScdDialog from "@/components/ScdDialog.vue";
import { computed } from "vue";

export default {
  name: "AccountModifyDialog",
  components: {
    "scd-dialog": ScdDialog,
  },
  props: {
    data: null,
  },
  emits: ["update:data"],
  setup(props, { emit }) {
    const accountData = computed({
      get: () => props.data,
      set: (val) => emit("update:data", val),
    });
    return { accountData };
  },
  data: function () {
    return {
      dialogData: {
        title: "修改帳號",
        buttons: ["確定修改", "取消"],
        onclose: this.onDialogClick,
      },
      accountTypeMapping: [
        { value: 1, label: "ADMIN" },
        { value: 2, label: "教師" },
        { value: 3, label: "學生" },
      ],
    };
  },
  methods: {
    onDialogClick: function (key) {
      this.data.onclose(key);
    },
  },
};
</script>

<style scoped>
.dialog-content {
  color: var(--fc6);
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: var(--gap-4) var(--gap-8);
  gap: var(--gap-2);
}
.item-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: var(--gap-1);
}
.item-title {
  flex: none;
  width: 100px;
}
.item-value {
  width: 200px;
  height: 28px;
}
</style>
