<template>
  <div class="tab-box">
    <div class="tab-content" v-if="question">
      <div class="edit-row">
        <div class="edit-label">標題</div>
        <input v-model="questionData.subject" />
      </div>
      <div class="edit-row">
        <div class="edit-label">說明</div>
        <textarea
          class="textarea"
          v-model="questionData.content"
        ></textarea>
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from "vue";

export default {
  setup(props, { emit }) {
    const questionData = computed({
      get: () => props.question,
      set: (val) => emit("update:question", val),
    });
    return { questionData };
  },
  props: {
    question: null,
  },
};
</script>

<style scoped>
.tab-box {
  padding: var(--gap-4);
}
.tab-content {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  background-color: white;
  display: flex;
  flex-direction: column;
  gap: var(--gap-2);
}
.edit-row {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: var(--gap-2);
}
.edit-label {
  flex: none;
  min-width: 100px;
  text-align: center;
  background-color: #4f618a;
  color: white;
  padding: 5px;
}
.textarea {
  flex: auto;
  height: 200px;
  resize: none;
}
input {
  display: block;
  height: 28px;
  width: calc(100% - 8px);
}
</style>