<template>
  <div>
    <main-layout>
      <div class="content-zone">
        <div class="content-box">
          <div class="button-row">
            <div
              class="add-button"
              v-if="store.hasPrivilege('c@question_edit')"
              @click="addQuestion()"
            >
              新增問題
            </div>
          </div>
          <div class="content-data">
            <table class="table">
              <tr>
                <th v-for="(column, index) in columns" :key="index">
                  {{ column.label }}
                </th>
                <th class="function-column">功能</th>
              </tr>
              <tr v-for="(data, row) in questionList" :key="row">
                <template v-for="(column, index) in columns" :key="index">
                  <td v-if="column.html" v-html="td(data, column)"></td>
                  <td v-else>
                    {{ td(data, column) }}
                  </td>
                </template>
                <td class="function-column">
                  <div class="table-function">
                    <div
                      class="table-button"
                      v-if="store.hasPrivilege('c@question_edit')"
                      @click="$router.push('/question_edit/' + data.id)"
                    >
                      編輯
                    </div>
                  </div>
                </td>
              </tr>
            </table>
          </div>
        </div>
        <div class="content-label">問題管理</div>
      </div>
    </main-layout>
  </div>
</template>

<script>
import MainLayout from "@/layouts/MainLayout.vue";
import scdapi from "@/scdapi.js";
import { useStore } from "@/stores/useStore";

export default {
  name: "PageExamList",
  components: {
    "main-layout": MainLayout,
  },
  setup() {
    const store = useStore();
    return {
      store,
    };
  },
  data: function () {
    return {
      // 題目狀態 //
      question_status_map: {
        0: "編輯中",
        1: "待公告",
        2: "測驗中",
        3: "待評分",
        4: "評分完畢",
        5: "評分公告",
      },
      columns: [
        { label: "編號", key: "id" },
        { label: "標題", key: "subject" },
        { label: "說明", key: "content" },
        {
          label: "狀態",
          key: "question_status",
          method: (x) => this.question_status_map[x],
        },
        { label: "建立時間", key: "created_at" },
        { label: "修改時間", key: "updated_at" },
      ],
      questionList: [],
    };
  },
  methods: {
    async getQuestionList() {
      try {
        this.questionList = await this.store.loading(
          async () => await scdapi.getQuestionList()
        );
      } catch (e) {
        this.store.alert(e.message);
      }
    },
    async addQuestion() {
      try {
        var questionId = await scdapi.newQuestion();
        this.$router.push("/question_edit/" + questionId);
      } catch (e) {
        this.store.alert(e.message);
      }
    },
    td(item, column) {
      if (column.method) {
        return column.method(item[column.key]);
      }
      return item[column.key];
    },
  },
  mounted: async function () {
    await this.getQuestionList();
    console.log(this.questionList);
  },
};
</script>

<style scoped>
.content-zone {
  flex: auto;
  padding: var(--gap-1);
  display: flex;
  flex-direction: row;
}
.content-box {
  flex: auto;
  margin: var(--gap-1);
  border: 1px solid var(--bc1);
  border-radius: var(--radius-1);
  padding: var(--gap-4);
  display: flex;
  flex-direction: column;
}
.content-data {
  overflow-y: auto;
}
.content-label {
  position: absolute;
  padding: var(--gap-1);
  background-color: var(--bc1);
  color: var(--fc1);
  border-radius: var(--radius-1);
}
/** 功能按鈕列 */
.button-row {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: var(--gap-2);
}
.add-button {
  color: var(--fc1);
  background-color: var(--bc1);
  padding: var(--gap-1);
  border-radius: var(--radius-1);
  cursor: pointer;
}
/** css about table */
.table * {
  --table-text: #444444;
  --table-bg0: white;
  --table-bg1: #1c6c69;
  --table-bg2: #78c4c4;
  --table-bg3: #c4f0f0;
}
.table {
  width: 100%;
  border-spacing: 1px;
  font-size: 14px;
  background-color: var(--table-bg0);
}
.table th {
  text-align: center;
  background-color: var(--table-bg1);
  color: var(--table-bg0);
  padding: 5px;
  position: sticky;
  top: 1px;
}
.table tr:nth-child(2n) td {
  background-color: var(--table-bg2);
}
.table tr:nth-child(2n + 1) td {
  background-color: var(--table-bg3);
}
.table td {
  text-align: center;
  color: var(--table-text);
  padding: 5px;
}
.table .table-function {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: var(--gap-1);
}
.table .table-button {
  background-color: var(--table-bg1);
  color: var(--table-bg0);
  padding: 5px 16px;
  border-radius: var(--radius-1);
  cursor: pointer;
}
</style>
