<template>
  <div class="tab-box">
    <div class="tab-content" v-if="examDrug">
      <div class="score-zone">
        <div class="score-label">評分</div>
        <textarea
          class="score-textarea"
          v-model="scoreData.score_comment"
        ></textarea>
        <div class="score-button-box">
          <label class="score-label"
            ><input
              type="checkbox"
              :checked="scoreData.score_status != 0"
              @change="scoreData.score_status = $event.target.checked ? 1 : 0"
            />完成評分</label
          >
        </div>
      </div>
      <div class="answer-row">
        <table-item
          class="drug-table-box"
          :datas="[examDrug]"
          :columns="columns"
          v-if="examDrug"
        ></table-item>
      </div>
      <div class="answer-row">
        <div class="answer-label">藥事原因</div>
        <div class="select-answer">{{ reason }}</div>
      </div>
      <div class="answer-row">
        <div class="answer-label">評估內容</div>
        <textarea
          class="textarea"
          :value="answer.reason_content"
          readonly
        ></textarea>
      </div>
      <div class="answer-row">
        <div class="answer-label">建議</div>
        <div class="select-answer">{{ sug }}</div>
      </div>
      <table class="table">
        <tr>
          <th style="width: 40%">學名</th>
          <th>劑量</th>
          <th>單位</th>
          <th>頻率</th>
          <th>途徑</th>
        </tr>
        <tr>
          <td><input :value="answer.sug_scientific_name" readonly /></td>
          <td><input :value="answer.sug_orderdose" readonly /></td>
          <td><input :value="answer.sug_doseunit" readonly /></td>
          <td><input :value="answer.sug_repeatpatterncode" readonly /></td>
          <td><input :value="answer.sug_routecode" readonly /></td>
        </tr>
      </table>
    </div>
  </div>
</template>
<script>
import TableItem from "@/components/TableItem.vue";
import { computed } from "vue";

export default {
  props: {
    examDrug: null,
    answer: null,
    score: null,
  },
  emits: ["update:score", "modify:score"],
  setup(props, { emit }) {
    const scoreData = computed({
      get: () => props.score,
      set: (val) => emit("update:score", val),
    });
    return { scoreData };
  },
  components: {
    "table-item": TableItem,
  },
  data: function () {
    return {
      reasonCodeOptions: [
        { value: null, label: "" },
        { value: 1, label: "建議修改劑量或頻次" },
        { value: 2, label: "建議修改期間或數量" },
        { value: 3, label: "建議修改途徑或劑型" },
        { value: 4, label: "不符適應症" },
        { value: 5, label: "用藥禁忌問題" },
        { value: 6, label: "藥品併用/交互作用問題" },
        { value: 7, label: "建議監測血中濃度" },
        { value: 8, label: "建議監測不良反應" },
      ],
      sugCodeOptions: [
        { value: null, label: "" },
        { value: 1, label: "修改藥品" },
        { value: 2, label: "修改劑量或頻次" },
        { value: 3, label: "修改天數或總量" },
        { value: 4, label: "修改途徑" },
        { value: 5, label: "取消處方" },
        { value: 6, label: "維持原用法" },
      ],
      columns: [
        { label: "序號", key: "exam_drug_id" },
        {
          label: "開方日",
          key: "startdatetime",
          method: function (x) {
            return x ? x.substr(0, 10) : "";
          },
        },
        { label: "處方號", key: "exam_drugorder_id" },
        { label: "藥品名稱", key: "scientific_name" },
        { label: "劑量", key: "orderdose" },
        { label: "單位", key: "doseunit" },
        { label: "頻率", key: "repeatpatterncode" },
        { label: "途徑", key: "routecode" },
        {
          label: "停用日",
          key: "enddatetime",
          method: function (x) {
            return x ? x.substr(0, 10) : "";
          },
        },
        {
          label: "OPD",
          key: "opd_medication",
          method: function (x) {
            return x ? "是" : "否";
          },
        },
      ],
    };
  },
  computed: {
    reason: function () {
      const reasonCodeOption = this.reasonCodeOptions.find(
        (x) => x.value == this.answer.reason_code
      );
      return reasonCodeOption == null ? "" : reasonCodeOption.label;
    },
    sug: function () {
      const sugCodeOption = this.sugCodeOptions.find(
        (x) => x.value == this.answer.sug_code
      );
      return sugCodeOption == null ? "" : sugCodeOption.label;
    },
  },
  watch: {
    "scoreData.score_comment"() {
      this.$emit("modify:score", this.score.user_id);
    },
    "scoreData.score_status"() {
      this.$emit("modify:score", this.score.user_id);
    },
  },
};
</script>

<style scoped>
.tab-box {
  padding: var(--gap-4);
}
.tab-content {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  background-color: white;
  display: flex;
  flex-direction: column;
  gap: var(--gap-2);
}
.answer-row {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: var(--gap-2);
}
.drug-table-box {
  flex: auto;
}
.answer-label {
  flex: none;
  min-width: 100px;
  text-align: center;
  background-color: #4f618a;
  color: white;
  padding: 5px;
}
.select-answer {
  min-width: 200px;
  min-height: 18px;
  padding: 5px 10px;
  border: 1px solid black;
}
.textarea {
  flex: auto;
  height: 200px;
  resize: none;
}
.table {
  width: 100%;
  border-spacing: 1px;
  font-size: 14px;
  background-color: white;
}
.table th {
  text-align: center;
  background-color: #4f618a;
  color: white;
  padding: 5px;
}
.table input {
  display: block;
  width: calc(100% - 8px);
}
.score-zone {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  border-bottom: 1px solid var(--bc5);
}
.score-label {
  color: var(--bc5);
  font-size: 20px;
  font-weight: bold;
}
.score-textarea {
  flex: none;
  height: 200px;
  resize: none;
  border-color: var(--bc5);
}
.score-button-box {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: var(--gap-2);
  padding: var(--gap-2);
}
</style>
