<template>
  <div>
    <router-view></router-view>
    <scd-popup></scd-popup>
    <!-- <router-bar></router-bar> -->
  </div>
</template>

<script>
// import RouterBar from './components/RouterBar.vue';
import ScdPopup from './components/ScdPopup.vue';
export default {
  name: 'App',
  components: {
    // RouterBar,
    ScdPopup
  },
}
</script>

<style>
#app {
  width: 100vw;
  height: 100vh;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
