<template>
  <div>
    <div class="table-box" v-if="columns">
      <table class="table">
        <tr>
          <th v-for="(column, index) in columns" :key="index">
            {{ column.label }}
          </th>
        </tr>
        <template v-for="(data, row) in datas" :key="row">
          <tr>
            <template v-for="(column, index) in columns" :key="index">
              <td v-if="column.html" v-html="td(data, column)" :rowspan="data.memo && (index == 0) ? 2 : 1"></td>
              <td v-else :rowspan="data.memo && (index == 0) ? 2 : 1">
                {{ td(data, column) }}
              </td>
            </template>
          </tr>
          <tr v-if="data.memo">
            <td colspan="10" class="memo">{{data.memo}}</td>
          </tr>
        </template>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  setup() {},
  props: {
    columns: null,
    datas: null,
  },
  methods: {
    td(item, column) {
      if (column.method) {
        return column.method(item[column.key]);
      }
      return item[column.key];
    },
    tdHtml(item, column) {
      console.log(column.html);
      return column.html;
    },
  },
};
</script>

<style scoped>
.table-box {
  width: 100%;
  height: 100%;
  overflow-y: auto;
}
.table {
  width: 100%;
  border-spacing: 1px;
  font-size: 14px;
  background-color: white;
}
.table th {
  text-align: center;
  background-color: #4f618a;
  color: white;
  padding: 5px;
  position: sticky;
  top: 1px;
}
.table tr:nth-child(2n) td {
  background-color: #adc1f1;
}
.table tr:nth-child(2n + 1) td {
  background-color: #cedaf6;
}
.table td {
  text-align: center;
  color: #444444;
  padding: 5px;
}
.table .memo {
  text-align: left;
}
</style>