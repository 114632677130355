import { defineStore } from "pinia";
import ScdDialog from "@/components/ScdDialog.vue";
import { shallowRef } from "vue";

export const useStore = defineStore('main', {
  state: () => {
    return {
      popupStack: [], // 存放popup顯示的內容 //
      popupIndex: 0,  // 提供popupStack中的物件，生成unique index //
      loadingCount: 0,     // loading堆疊的層數(超過一層就會show loading) //
      user: {
        checked: false, // 是否經過第一次讀取check過 //
        name: null,
        account_type: null,
      },
    };
  },
  getters: {
    privileges(state) {
      switch (state.user.account_type) {
        case 1: // admin //
          return [
            'c@border_list', 'c@border_detail',
            'c@question_manage', 'c@question_edit',
            'c@account_list', 'c@account_add', 'c@account_modify',
            'c@border_manage', 'c@border_edit',
            'c@exam',
            'c@exam_score',
            'c@response',
            'c@list_response',
          ];
        case 2: // 教師 //
          return [
            'c@border_list', 'c@border_detail',
            'c@exam_score',
            'c@question_manage', 'c@question_edit',
            'c@border_manage', 'c@border_edit',
            'c@response',
            'c@list_response',
          ];
        case 3: // 學生 //
          return [
            'c@border_list', 'c@border_detail',
            'c@exam',
            'c@response',
          ];
      }
    }
  },
  actions: {
    /** 生成下一個popupStack 的 index */
    nextPopupIndex: function () {
      this.popupIndex++;
      return this.popupIndex;
    },
    // 顯示某個內嵌元件的dialog //
    dialog: async function(component, data = {}) {
      var popup = {
        index: this.nextPopupIndex(),
        type: 'dialog',
        component,
        data,
      };
      var result = await new Promise((resolve) => {
        popup.data.onclose = resolve;
        this.popupStack.push(popup);
      });
      // remove data from this.popupStack //
      var index = this.popupStack.findIndex(d => d.index == popup.index);
      this.popupStack.splice(index, 1);

      return result;
    },
    /** 顯示 alert 的 dialog */
    alert: async function (content, title = null, button = ['確定']) {
      return await this.dialog(shallowRef(ScdDialog), {title, content, buttons:button});
    },
    confirm: async function (content, title = null, button = ['確定', '取消']) {
      return await this.dialog(shallowRef(ScdDialog), {title, content, buttons:button});
    },
    beginLoading: async function() {
      this.loadingCount ++;
    },
    endLoading: async function() {
      if (this.loadingCount > 0) {
        this.loadingCount --;
      }
    },
    // 針對一個 async func 進行 loading 顯示 //
    loading: async function(func) {
      await this.beginLoading();
      try {
        return await func();
      } finally {
        await this.endLoading();
      }
    },
    // 權限管理 //
    hasPrivilege: function(privilege) {
      return this.privileges.includes(privilege);
    }
  }
});
