<template>
  <div>
    <div class="cover" v-if="popupData">
      <scd-loading v-if="popupData.type == 'loading'"></scd-loading>
      <component
        v-if="popupData.type == 'dialog'"
        :is="popupData.component"
        :data="popupData.data"
      ></component>
    </div>
  </div>
</template>

<script>
import ScdLoading from "./ScdLoading.vue";
import { useStore } from "@/stores/useStore.js";
import { computed } from "@vue/runtime-core";

export default {
  setup() {
    const store = useStore();
    const popupData = computed(() => {
      return store.popupStack.length > 0
        ? store.popupStack[store.popupStack.length - 1]
        : store.loadingCount > 0
        ? { type: "loading" }
        : null;
    });
    return {
      popupData,
    };
  },
  components: {
    ScdLoading,
  },
};
</script>

<style scoped>
.cover {
  z-index: 100;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: #00000088;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>