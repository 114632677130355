<template>
  <div>
    <main-layout>
      <div class="content-zone">
        <div class="content-box">
          <div class="content-data">
            <div class="block-back">
              <div
                class="block"
                v-for="(block, index) in question.blocks"
                :key="index"
              >
                <div v-if="block.type == 'textarea'">
                  <p>{{ block.title }}</p>
                  <textarea v-model="answer[index]"></textarea>
                </div>
                <div v-if="block.type == 'bool'">
                  <p>{{ block.title }}</p>
                </div>
              </div>
              <div>
                <div class="button" @click="onSave">確認儲存</div>
              </div>
            </div>
          </div>
        </div>
        <div class="content-label">反饋意見</div>
      </div>
    </main-layout>
  </div>
</template>

<script>
import { useStore } from "@/stores/useStore";
import scdapi from "@/scdapi.js";
import MainLayout from "@/layouts/MainLayout.vue";

export default {
  setup() {
    const store = useStore();
    return {
      store,
    };
  },
  name: "PageResponseForm",
  components: {
    MainLayout,
  },
  data: function () {
    return {
      question: {
        blocks: [{ type: "textarea", title: "在使用本系統後，煩請提供你的意見：" }],
      },
      answer: [""],
    };
  },
  methods: {
    onSave: async function() {
      if (this.answer[0].trim() == '') {
        await this.store.alert('請填寫意見內容。');
        return;
      }
      try {
        await this.store.loading(async () => {
          await scdapi.addResponse(this.answer[0].trim());
        });
      } catch (e) {
        await this.store.alert(e.message);
        return;
      }
      await this.store.alert('感謝您寶貴的意見。');
      this.$router.push("/home");
    }
  }
};
</script>

<style scoped>
.content-zone {
  flex: auto;
  padding: var(--gap-1);
  display: flex;
  flex-direction: row;
}
.content-box {
  flex: auto;
  margin: var(--gap-1);
  border: 1px solid var(--bc1);
  border-radius: var(--radius-1);
  padding: var(--gap-4);
  display: flex;
  flex-direction: column;
}
.content-data {
  overflow-y: auto;
}
.content-label {
  position: absolute;
  padding: var(--gap-1);
  background-color: var(--bc1);
  color: var(--fc1);
  border-radius: var(--radius-1);
}
.block-back {
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 16px;
}
.block {
  width: 60%;
  padding: 16px;
}
.block textarea {
  width: 100%;
  height: 200px;
  resize: none;
}
.button {
  color: var(--fc1);
  background-color: var(--bc1);
  padding: var(--gap-1);
  border-radius: var(--radius-1);
  cursor: pointer;
}
</style>