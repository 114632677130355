<!-- 上方的工具列 -->
<template>
  <div class="header-bar">
    <div class="header-left">
      <img class="login-logo" src="@/assets/logo.png" />
      <div class="title">線上用藥案例平台</div>
    </div>
    <div class="header-right">
      <div>
        <div class="user-button" @click="showUserMenu()">
          {{ this.store.user.name }}<i class="bi bi-caret-down-fill"></i>
        </div>
        <div class="user-menu-anchor" v-if="userMenuVisiable">
          <div class="user-meun-background" @click="hideUserMenu()"></div>
          <div class="user-menu">
            <div
              class="user-menu-button"
              @click="
                hideUserMenu();
                doChangePassword();
              "
            >
              變更密碼
            </div>
            <div
              class="user-menu-button"
              @click="
                hideUserMenu();
                doLogout();
              "
            >
              Logout
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { shallowRef, toRaw } from "vue";
import scdapi from "@/scdapi.js";
import { useStore } from "@/stores/useStore";
import ChangePassword from "@/components/ChangePassword.vue";

export default {
  setup() {
    const store = useStore();
    return {
      store,
    };
  },
  data: function () {
    return {
      userMenuVisiable: false,
    };
  },
  methods: {
    doLogout: async function () {
      try {
        await this.store.loading(async () => await scdapi.logout());
        this.store.user.name = null;
        this.$router.push("/login");
      } catch (e) {
        await this.store.alert(e.message);
        this.store.user.name = null;
        this.$router.push("/login");
      }
    },
    doChangePassword: function () {
      var data = toRaw({}); // 暫存資料，用來保存 dialog 切換時的資料 //
      this.store.dialog(shallowRef(ChangePassword), data);
    },
    showUserMenu() {
      this.userMenuVisiable = true;
    },
    hideUserMenu() {
      this.userMenuVisiable = false;
    },
  },
};
</script>

<style scoped>
.header-bar {
  z-index: 10;
  background: linear-gradient(white 0%, white 25%, var(--bc2) 25%, var(--bc2) 100%);
  display: flex;
  flex-direction: row;
  align-items: stretch;
}
.header-left {
  background-color: var(--bc1);
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: var(--gap-1) var(--gap-2);
}
.header-right {
  flex: auto;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-end;
  gap: var(--gap-2);
  padding: 0 var(--gap-2);
}
.user-button {
  position: relative;
  background-color: var(--bc1);
  color: var(--fc1);
  border-radius: var(--radius-1);
  padding: 12px var(--gap-2);
  user-select: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: var(--gap-1);
  box-shadow: -4px 4px 0px white;
}
.user-meun-background {
  position:absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}
.user-menu {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}
.user-menu-button {
  color: var(--fc2);
  background-color: #ecaac8;
  border: 1px solid var(--bc1);
  text-align: center;
  padding: var(--gap-1) var(--gap-2);
  cursor: pointer;
}
.login-logo {
  width: 32px;
  height: 32px;
}
.title {
  color: var(--fc1);
  font-size: 20px;
}
</style>
