<template>
  <div class="tab-box">
    <div class="tab-content" v-if="functionMode == 0">
      <div>{{ content }}</div>
    </div>
    <textarea
      class="textarea"
      v-if="functionMode == 1"
      v-model="content"
    ></textarea>
  </div>
</template>

<script>
import { computed } from "vue";

export default {
  setup(props, { emit }) {
    const content = computed({
      get: () => props.patientContent,
      set: (val) => emit("update:patientContent", val),
    });
    return { content };
  },
  props: {
    functionMode: {
      type: Number,
      default: 0,
    },
    patientContent: null,
  },
};
</script>

<style scoped>
.tab-box {
  padding: var(--gap-4);
}
.tab-content {
  width: 100%;
  height: 100%;
  background-color: white;
  overflow-y: auto;
}
.textarea {
  width: 100%;
  height: 100%;
  resize: none;
}
</style>