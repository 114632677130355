<template>
  <div class="login-page">
    <div class="login-box">
      <div class="box-header">
        <img class="login-logo" src="@/assets/logo.png" />
        <span>線上用藥案例平台</span>
      </div>
      <div class="box-content">
        <div class="login-title">Email Address</div>
        <input class="login-input" type="email" v-model="email" />
        <div class="login-title">Password</div>
        <input class="login-input" type="password" v-model="password" />
      </div>
      <div class="button-box">
        <button class="login-button" @click="doLogin()">登入</button>
      </div>
    </div>
  </div>
</template>

<script>
import scdapi from "@/scdapi.js";
import { useStore } from "@/stores/useStore";

export default {
  setup() {
    const store = useStore();
    return {
      store,
    };
  },
  name: "PageLogin",
  data: function () {
    return {
      email: "",
      password: "",
    };
  },
  methods: {
    doLogin: async function () {
      try {
        await this.store.loading(
          async () => await scdapi.login(this.email, this.password)
        );
        var userInfo = await this.store.loading(
          async () => await scdapi.getUserInfo()
        );
        this.store.user.name = userInfo.name;
        this.store.user.account_type = userInfo.account_type;
        this.$router.push("/home");
      } catch (e) {
        await this.store.alert(e.message, "登入失敗");
      }
    },
  },
};
</script>

<style scoped>
.login-page {
  background-color: var(--bc2);
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.login-box {
  background-color: white;
  border-radius: var(--radius-1);
  overflow: hidden;
}
.box-header {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--bc1);
  color: var(--fc1);
  padding: var(--gap-4);
  gap: var(--gap-2);
  font-size: 1.5em;
}
.login-logo {
  width: 32px;
  height: 32px;
}
.box-content {
  background-color: var(--bc4);
  color: var(--fc4);
  display: flex;
  flex-direction: column;
  padding: var(--gap-4);
  gap: var(--gap-2);
}
.login-title {
  font-size: 1.5em;
  width: 320px;
}
.login-input {
  border: none;
  outline: none;
  border-bottom: 1px solid #888888;
  width: 320px;
  font-size: 1.5em;
}
.button-box {
  padding: var(--gap-4);
  display: flex;
  align-items: center;
  justify-content: center;
}
.login-button {
  background-color: var(--bc1);
  color: var(--fc1);
  width: 200px;
  height: 48px;
  font-size: 1.5em;
  border: none;
  border-radius: var(--radius-1);
  cursor: pointer;
}
</style>
