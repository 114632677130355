import axios from 'axios';

export default new function () {

  // 定義錯誤ERROR_CODE //
  this.ERROR_CODE_HTTP_ERROR = -1;
  this.ERROR_CODE_API_PROTOCOL_ERROR = -2;
  this.ERROR_CODE_SUCCESS = 0;
  this.ERROR_CODE_AUTH_FAIL = 1;
  this.ERROR_CODE_UNKNOWN = 9999;

  // board 列表相關參數 //
  this.START_BOARD_ID = null;
  this.END_BOARD_ID = -1;

  /** 登入 */
  this.login = async (email, password) => {
    return await this.post('/sapi/login', { email, password });
  }
  /** 登出 */
  this.logout = async () => {
    return await this.post('/sapi/logout');
  }
  this.getUserInfo = async () => {
    return await this.post('/sapi/getUserInfo');
  }
  this.changePassword = async (old_password, new_password) => {
    return await this.post('/sapi/changePassword', { old_password, new_password });
  }
  /** 取得看板資料 */
  this.getBoardList = async (last_board_id = null) => {
    return await this.post('/sapi/getBoardList', { last_board_id });
  }

  /** 取得看板管理列表 */
  this.getManageBoardList = async () => {
    return await this.post('/sapi/getManageBoardList');
  }

  /** 異動看板上、下線狀態 */
  this.boardModifyStatus = async (board_id, status) => {
    return await this.post('/sapi/boardModifyStatus', { board_id, status });
  }

  /** 取得編輯的看板資料 */
  this.getManageBoard = async (board_id) => {
    return await this.post('/sapi/getManageBoard', { board_id });
  }

  /** 儲存看板資料 */
  this.saveManageBoard = async (board_id, subject, content, exam_id) => {
    return await this.post('/sapi/saveManageBoard', { board_id, subject, content, exam_id });
  }

  /** 新增看板資料 */
  this.addManageBoard = async (subject, content, exam_id) => {
    return await this.post('/sapi/addManageBoard', { subject, content, exam_id });
  }
  
  /** 取得測驗列表(提供看板編輯時，選擇測驗) */
  this.getExamList = async () => {
    return await this.post('/sapi/getExamList');
  }

  /** 取得測驗 */
  this.getExam = async (exam_id) => {
    return await this.post('/sapi/exam', { exam_id });
  }

  /** 取得題目資料 */
  this.getQuestion = async (question_id) => {
    return await this.post('/sapi/question', { question_id });
  }

  /** 取得之前的作答 */
  this.getAnswer = async (question_id) => {
    return await this.post('/sapi/answer', { question_id });
  }

  /** 取得之前的作答 */
  this.saveAnswer = async (question_id, answers) => {
    return await this.post('/sapi/saveAnswer', { question_id, answers: JSON.stringify(answers) });
  }

  /** 讀取評分列表 */
  this.getScoreList = async (question_id) => {
    return await this.post('/sapi/scoreList', { question_id });
  }

  /** 寫回評分結果 */
  this.saveScore = async (question_id, user_id, score_status, score_comment) => {
    return await this.post('/sapi/saveScore', { question_id, user_id, score_status, score_comment });
  }

  /** 取得帳號列表 */
  this.getAccountList = async () => {
    return await this.post('/sapi/account/list');
  }

  /** 修改帳號狀態(帳號管理) */
  this.accountModifyStatus = async (id, status) => {
    return await this.post('/sapi/account/modifystatus', { id, status });
  }

  /** 修改資料(帳號管理) */
  this.accountModifyData = async (id, name, account_type) => {
    return await this.post('/sapi/account/modify', { id, name, account_type });
  }

  /** 變更密碼(帳號管理) */
  this.accountChangePassword = async (id, password) => {
    return await this.post('/sapi/account/changepassword', { id, password });
  }

  /** 新增帳號(帳號管理) */
  this.accountAdd = async (name, email, account_type, password) => {
    return await this.post('/sapi/account/add', { name, email, account_type, password });
  }

  // 題目管理 //
  /** 題目列表 */
  this.getQuestionList = async () => {
    return await this.post('/sapi/examEdit/questionList', {});
  }
  
  this.saveQuestion = async (question, patient, exam_drug_list) => {
    return await this.post('/sapi/examEdit/saveQuestion', {question, patient, exam_drug_list});
  }

  this.newQuestion = async () => {
    return await this.post('/sapi/examEdit/newQuestion', {});
  }

  // 反饋意見 //
  this.addResponse = async (content) => {
    return await this.post('/sapi/addResponse', { content });
  }

  this.listResponse = async () => {
    return await this.post('/sapi/listResponse', {});
  }
  
  // 共用資料 //
  this.getMeasureType = async () => {
    return await this.post('/sapi/measureType', {});
  }

  /** 與server之間 post 協定通訊 */
  this.post = async (...args) => {
    try {
      var response = await axios.post(...args);
    }
    catch (error) {
      throw {
        code: this.ERROR_CODE_HTTP_ERROR,
        message: error.response.statusText,
        data: null
      };
    }
    if (response.data == null || response.data.errorCode === undefined) {
      throw {
        code: this.ERROR_CODE_API_PROTOCOL_ERROR,
        message: "傳輸資料協定有誤，無發確認錯誤原因",
        data: null
      };
    }
    if (response.data.errorCode != this.ERROR_CODE_SUCCESS) {
      throw {
        code: response.data.errorCode,
        message: response.data.errorMsg,
        data: null
      };
    }
    return response.data.data;
  }
}()
