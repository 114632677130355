<template>
  <div class="tab-box">
    <div class="tab-content" v-if="examDrug">
      <div class="answer-row">
        <table-item
          class="drug-table-box"
          :datas="[examDrug]"
          :columns="columns"
          v-if="examDrug"
        ></table-item>
        <div class="remove-button" @click="onRemoveClick">刪除</div>
      </div>
      <div class="answer-row">
        <div class="answer-label">藥事原因</div>
        <select class="select" v-model="answerData.reason_code">
          <option
            v-for="reasonCode in reasonCodeOptions"
            :key="reasonCode.value"
            :value="reasonCode.value"
          >
            {{ reasonCode.label }}
          </option>
        </select>
      </div>
      <div class="answer-row">
        <div class="answer-label">評估內容</div>
        <textarea class="textarea" v-model="answerData.reason_content"></textarea>
      </div>
      <div class="answer-row">
        <div class="answer-label">建議</div>
        <select class="select" v-model="answerData.sug_code">
          <option
            v-for="sugCode in sugCodeOptions"
            :key="sugCode.value"
            :value="sugCode.value"
          >
            {{ sugCode.label }}
          </option>
        </select>
      </div>
      <table class="table">
        <tr>
          <th style="width: 40%">學名</th>
          <th>劑量</th>
          <th>單位</th>
          <th>頻率</th>
          <th>途徑</th>
        </tr>
        <tr>
          <td><input v-model="answerData.sug_scientific_name"/></td>
          <td><input v-model="answerData.sug_orderdose"/></td>
          <td><input v-model="answerData.sug_doseunit"/></td>
          <td><input v-model="answerData.sug_repeatpatterncode"/></td>
          <td><input v-model="answerData.sug_routecode"/></td>
        </tr>
      </table>
    </div>
    <div v-else>
      <div class="answer-hint">尚未選擇問題處方。請切換到"處方"分頁，選擇有問題處方。</div>
    </div>
  </div>
</template>
<script>
import TableItem from "@/components/TableItem.vue";
import { computed } from "vue";

export default {
  props: {
    examDrug: null,
    answer: null,
  },
  emits: [
    'update:answer',
    'remove:answer',
  ],
  setup(props, { emit }) {
    const answerData = computed({
      get: () => props.answer,
      set: val => emit("update:answer", val),
    });
    return {answerData};
  },
  components: {
    "table-item": TableItem,
  },
  data: function () {
    return {
      reasonCodeOptions: [
        { value: null, label: "" },
        { value: 1, label: "建議修改劑量或頻次" },
        { value: 2, label: "建議修改期間或數量" },
        { value: 3, label: "建議修改途徑或劑型" },
        { value: 4, label: "不符適應症" },
        { value: 5, label: "用藥禁忌問題" },
        { value: 6, label: "藥品併用/交互作用問題" },
        { value: 7, label: "建議監測血中濃度" },
        { value: 8, label: "建議監測不良反應" },
      ],
      sugCodeOptions: [
        { value: null, label: "" },
        { value: 1, label: "修改藥品" },
        { value: 2, label: "修改劑量或頻次" },
        { value: 3, label: "修改天數或總量" },
        { value: 4, label: "修改途徑" },
        { value: 5, label: "取消處方" },
        { value: 6, label: "維持原用法" },
      ],
      columns: [
        { label: "序號", key: "exam_drug_id" },
        {
          label: "開方日",
          key: "startdatetime",
          method: function (x) {
            return x ? x.substr(0, 10) : "";
          },
        },
        { label: "處方號", key: "exam_drugorder_id" },
        { label: "藥品名稱", key: "scientific_name" },
        { label: "劑量", key: "orderdose" },
        { label: "單位", key: "doseunit" },
        { label: "頻率", key: "repeatpatterncode" },
        { label: "途徑", key: "routecode" },
        {
          label: "停用日",
          key: "enddatetime",
          method: function (x) {
            return x ? x.substr(0, 10) : "";
          },
        },
        {
          label: "OPD",
          key: "opd_medication",
          method: function (x) {
            return x ? "是" : "否";
          },
        },
      ],
    };
  },
  methods: {
    onRemoveClick() {
      this.$emit("remove:answer", this.answer.exam_drug_id);
    }
  }
};
</script>

<style scoped>
.tab-box {
  padding: var(--gap-4);
}
.tab-content {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  background-color: white;
  display: flex;
  flex-direction: column;
  gap: var(--gap-2);
}
.answer-row {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: var(--gap-2);
}
.drug-table-box {
  flex: auto;
}
.remove-button {
  font-size: 12px;
  padding: var(--gap-1);
  background-color: white;
  color: red;
  font-weight: bold;
  border: 1px solid red;
  border-radius: var(--radius-1);
  cursor: pointer;
}
.answer-label {
  flex: none;
  min-width: 100px;
  text-align: center;
  background-color: #4f618a;
  color: white;
  padding: 5px;
}
.select {
  height: 28px;
}
.textarea {
  flex: auto;
  height: 200px;
  resize: none;
}
.table {
  width: 100%;
  border-spacing: 1px;
  font-size: 14px;
  background-color: white;
}
.table th {
  text-align: center;
  background-color: #4f618a;
  color: white;
  padding: 5px;
}
.table input {
  display: block;
  width: calc(100% - 8px);
}
.answer-hint {
  text-align: center;
  font-size: 18px;
}
</style>
