<template>
  <div>
    <scd-dialog :data="dialogData">
      <div class="dialog-content">
        <div class="item-row">
          <div class="item-title">名稱</div>
          <div class="item-value">{{ accountData.name }}</div>
        </div>
        <div class="item-row">
          <div class="item-title">帳號(email)</div>
          <div class="item-value">{{ accountData.email }}</div>
        </div>
        <div class="item-row">
          <div class="item-title">新密碼</div>
          <input class="item-value" type="password" v-model="accountData.password" />
        </div>
        <div class="item-row">
          <div class="item-title">再次輸入新密碼</div>
          <input class="item-value" type="password" v-model="accountData.passwordConfirm" />
        </div>
      </div>
    </scd-dialog>
  </div>
</template>

<script>
import ScdDialog from "@/components/ScdDialog.vue";
import { useStore } from "@/stores/useStore";
import { computed } from "vue";

export default {
  name: "AccountChangePassword",
  components: {
    "scd-dialog": ScdDialog,
  },
  props: {
    data: null,
  },
  emits: ["update:data"],
  setup(props, { emit }) {
    const store = useStore();
    const accountData = computed({
      get: () => props.data,
      set: (val) => emit("update:data", val),
    });
    return { accountData, store };
  },
  data: function () {
    return {
      dialogData: {
        title: "變更密碼",
        buttons: ["確定變更", "取消"],
        onclose: this.onDialogClick,
      }
    };
  },
  methods: {
    onDialogClick: function (key) {
      if (key == 0) {
        if (this.accountData.password == null) {
          this.store.alert("請輸入新密碼");
          return;
        }
        if (this.accountData.password != this.accountData.passwordConfirm) {
          this.store.alert("兩次輸入的密碼不相同，請確認");
          return;
        }
      }
      this.data.onclose(key);
    },
  },
};
</script>

<style scoped>
.dialog-content {
  color: var(--fc6);
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: var(--gap-4) var(--gap-8);
  gap: var(--gap-2);
}
.item-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: var(--gap-1);
}
.item-title {
  flex: none;
  width: 120px;
}
.item-value {
  width: 200px;
  height: 28px;
}
</style>
