<template>
  <div class="edit-zone">
    <div class="action-row">
      <div class="action-button" @click="saveBorder">儲存</div>
      <div class="action-button" @click="abortBorder">回列表</div>
    </div>
    <div class="content-box" v-if="border">
      <div class="content-row">
        <span class="content-label">測驗項目</span>
        <select v-model="border.exam_id">
          <option value="">(無)</option>
          <option v-for="exam in examList" :key="exam.exam_id" :value="exam.exam_id">
            {{ "(" + exam.exam_id + ") " + exam.question_subject}}
          </option>
        </select>
      </div>
      <div class="content-row">
        <span class="content-label">標題</span>
        <input v-model="border.subject" />
      </div>
      <div class="content-row">
        <div>內容</div>
      </div>
      <ckeditor :editor="editor" v-model="border.content" :config="editorConfig"></ckeditor>
    </div>
  </div>
</template>

<script>
import scdapi from "@/scdapi.js";
import { useStore } from "@/stores/useStore";
import CKEditor from '@ckeditor/ckeditor5-vue';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

export default {
  name: "PageBorderManage",
  setup() {
    const store = useStore();
    return {
      store,
    };
  },
  components: {
    ckeditor: CKEditor.component
  },
  data: function () {
    return {
      border: null,
      examList: [], // 測驗列表(提供選擇)
      editor: ClassicEditor,
      editorData: '',
      editorConfig: {
        toolbar: [
          'undo', 'redo',
          '|', 'heading',
          '|', 'bold', 'italic',
          '|', 'link',
          '|', 'bulletedList', 'numberedList'
        ],
      }
    };
  },
  methods: {
    async saveBorder() {
      if (this.border.id == null) {
        // 新增 //
        try {
          var result = await this.store.loading(
            async () => {
              // 新增 //
              return await scdapi.addManageBoard(
                this.border.subject,
                this.border.content,
                this.border.exam_id
              );
          });
          this.store.alert("新增成功");
          this.$router.push('/border_edit/' + result.board_id);
        } catch (e) {
          await this.store.alert(e.message, "新增失敗");
        }
      } else {
        try {
          await this.store.loading(
            async () =>
              await scdapi.saveManageBoard(
                this.border.id,
                this.border.subject,
                this.border.content,
                this.border.exam_id
              )
          );
          this.store.alert("儲存成功");
        } catch (e) {
          await this.store.alert(e.message, "儲存失敗");
        }
      }
    },
    async newBorder() {
      this.border = {
        id: null,
        subject: '',
        content: '',
        exam_id: '',
      };
    },
    async abortBorder() {
      if (
        (await this.store.confirm(
          "編輯異動將不會儲存，是否要離開？",
          "確認離開"
        )) == 0
      ) {
        this.$router.push("/border_manage");
      }
    },
  },
  computed: {
    border_id() {
      return this.$route.params.border_id;
    },
  },
  mounted: async function () {
    try {
      this.examList = await this.store.loading(
        async () => await scdapi.getExamList()
      );
      if (this.border_id != '') {
        this.border = await this.store.loading(
          async () => await scdapi.getManageBoard(this.border_id)
        );
        // 修正 null 類別資料 //
        this.border.exam_id = this.border.exam_id == null ? "" : this.border.exam_id;
      } else {
        this.newBorder();
      }
    } catch (e) {
      this.store.alert(e.message);
    }
  },
};
</script>

<style scoped>
.edit-zone {
  padding: var(--gap-2);
}
.action-row {
  top: var(--gap-1);
  left: var(--gap-2);
  display: flex;
  flex-direction: row;
  gap: var(--gap-1);
}
.action-button {
  color: var(--bc7);
  background-color: var(--fc7);
  padding: var(--gap-1);
  border-radius: var(--radius-1);
  cursor: pointer;
}
.content-box {
  flex: auto;
  margin: var(--gap-1);
  border: 1px solid var(--bc1);
  border-radius: var(--radius-1);
  padding: var(--gap-1);
  display: flex;
  flex-direction: column;
}
.content-row {
  margin: var(--gap-1);
  display: flex;
  flex-direction: row;
  align-content: center;
}
.content-label {
  padding: var(--gap-1);
}
</style>

<style>
/* ckeditor 設定最小編輯大小 */
.ck-editor__editable[role="textbox"] {
    /* Editing area */
    min-height: 200px;
}
</style>