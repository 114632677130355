import * as VueRouter from 'vue-router';
import PageLogin from '@/pages/PageLogin.vue';
import PageHome from '@/pages/PageHome.vue';
import PageAccount from '@/pages/PageAccount.vue';
import PageExam from '@/pages/PageExam.vue';
import PageExamList from '@/pages/PageExamList.vue';
import PageQuestionEdit from '@/pages/PageQuestionEdit.vue';
import PageExamScore from '@/pages/PageExamScore.vue';
import PageQuestion from '@/pages/PageQuestion.vue';
import PageQuestionScore from '@/pages/PageQuestionScore.vue';
import PageBorderManage from '@/pages/PageBorderManage.vue';
import PageBorderEdit from '@/pages/PageBorderEdit.vue';
import PageResponseForm from '@/pages/PageResponseForm.vue';
import PageListResponseForm from '@/pages/PageListResponseForm.vue';
import { useStore } from "@/stores/useStore";
import scdapi from "@/scdapi.js";

const routes = [
  { path: '/', redirect: '/home' },
  { path: '/login', name: 'login', component: PageLogin },
  { path: '/home', name: 'home', component: PageHome },
  { path: '/exam_list', name: 'exam_list', component: PageExamList },
  { path: '/question_edit/:question_id', name: 'question_edit', component: PageQuestionEdit },
  { path: '/account', name: 'account', component: PageAccount },
  { path: '/exam/:exam_id', name: 'exam', component: PageExam },
  { path: '/exam_score/:exam_id', name: 'exam_score', component: PageExamScore },
  { path: '/question/:exam_id/:question_id', name: 'question', component: PageQuestion },
  { path: '/question_score/:exam_id/:question_id', name: 'question_score', component: PageQuestionScore },
  { path: '/border_manage', name: 'border_manage', component: PageBorderManage},
  { path: '/border_edit/:border_id?', name: 'border_edit', component: PageBorderEdit},
  { path: '/response', name: 'response', component: PageResponseForm},
  { path: '/list_response', name: 'list_response', component: PageListResponseForm},
  
];

const router = VueRouter.createRouter({
  history: VueRouter.createWebHashHistory(),
  routes,
});

router.beforeEach(async (to) => {
  const store = useStore();
  // 檢查個人資料是否已經讀取 //
  if (!store.user.checked) {
    store.user.checked = true;
    try {
      var userInfo = await scdapi.getUserInfo();
      store.user.name = userInfo.name;
      store.user.account_type = userInfo.account_type;
    } catch (e) {
      return { name: 'login' };
    }
  }
  // Login頁面處理(登入導向Home)，未登入導向 login //
  if (to.name == 'login') {
    if (store.user.name != null) {
      return { name: 'home' };
    }
  } else {
    if (store.user.name == null) {
      return { name: 'login' };
    }
  }
  // 權限檢查 //
  switch (to.name) {
    case 'account':
      if (!store.hasPrivilege('c@account_list')) { return { name: 'home' }; } break;
    case 'exam':
    case 'question':
      if (!store.hasPrivilege('c@exam')) { return { name: 'home' }; } break;
    case 'exam_score':
    case 'question_score':
      if (!store.hasPrivilege('c@exam_score')) { return { name: 'home' }; } break;
    case 'exam_list':
      if (!store.hasPrivilege('c@question_manage')) { return { name: 'home' }; } break;
    case 'question_edit':
      if (!store.hasPrivilege('c@question_edit')) { return { name: 'home' }; } break;
    case 'response':
      if (!store.hasPrivilege('c@response')) { return { name: 'home' }; } break;
    case 'list_response':
      if (!store.hasPrivilege('c@list_response')) { return { name: 'home' }; } break;
  }
});

export { router };

