<template>
  <div class="tab-box" :class="'tab-box-' + tabAlign">
    <div
      class="tab-item"
      :class="activeTab === item.value ? 'tab-item-active' : ''"
      v-for="(item, index) in tabs"
      :key="index"
      @click="tabClick(item.value)"
    >
      {{ item.label }}
    </div>
  </div>
</template>

<script>
export default {
  setup() {},
  props: {
    tabAlign: { default: "right" },
    tabs: { type: Array, default: () => [] },
    activeTab: null,
  },
  data: function () {
    return {};
  },
  methods: {
    tabClick(value) {
      if (value != this.activeTab) {
        this.$emit("tab-click", value);
      }
    },
  },
};
</script>

<style scoped>
.tab-box {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}
.tab-box-left {
  justify-content: left;
}
.tab-box-right {
  justify-content: right;
}
.tab-item {
  border-radius: var(--radius-1) var(--radius-1) 0 0;
  padding: var(--gap-1);
  background-color: #e0e0e0;
  border-top: 1px solid #cccccc;
  border-left: 1px solid #cccccc;
  border-right: 1px solid #cccccc;
  color: #444444;
  cursor: pointer;
}
.tab-item-active {
  background-color: #ffffff;
}
</style>